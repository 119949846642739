import { IconButton } from '@dropbox/dig-components/buttons';
import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { ListViewLine } from '@dropbox/dig-icons/assets';
import { DashWordmark, WordmarkLogo } from '@dropbox/dig-logos';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import {
  useAllStacksAugustRevisionEnabled,
  useIsStartPageAugustRevisionEnabled,
  useStackPageAugustRevisionEnabled,
} from '@mirage/august-revision-hook';
import { useCurrentBackgroundTheme } from '@mirage/dash-component-library/themes/Stacks';
import { useSurveyContext } from '@mirage/feedback/hooks';
import { SurveyEvent } from '@mirage/feedback/types';
import {
  generateSearchURL,
  getSearchFromURLParams,
  useQueryParams,
} from '@mirage/search/hooks/useQueryParams';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { removePreviousQueryFromCache } from '@mirage/service-typeahead-search';
import { useTypeaheadSearch } from '@mirage/service-typeahead-search/hooks/useTypeaheadSearch';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { BetaBadge } from '@mirage/shared/badges/BetaBadge';
import { usePageScrollListener } from '@mirage/shared/hooks/usePageScrollListener';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import { activeStackAtom } from '@mirage/stacks/ActiveStack/atoms';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  globalNavIsCollapsedAtom,
  globalNavIsMobileAtom,
} from '../GlobalNav/atoms';
import { SearchBarWithTypeahead } from '../SearchBarWithTypeahead';
import styles from './TopNav.module.css';

import type { SearchFilter } from '@mirage/shared/search/search-filters';

export const TopNav: React.FC = () => {
  const navigate = useNavigate();

  const [isCollapsed, setIsCollapsed] = useAtom(globalNavIsCollapsedAtom);
  const { searchAttemptSessionManager } = useMirageAnalyticsContext();
  const { publishEvent } = useSurveyContext();
  const stackFromFullStackPage = useAtomValue(activeStackAtom);
  const backgroundTheme = useCurrentBackgroundTheme(stackFromFullStackPage);
  const { pageScrolled } = usePageScrollListener();

  const isAugustStackPageEnabled = useStackPageAugustRevisionEnabled();
  const isAugustStartPageEnabled = useIsStartPageAugustRevisionEnabled();
  const isAugustAllStacksPageEnabled = useAllStacksAugustRevisionEnabled();
  const augustRevisionEnabled =
    isAugustStackPageEnabled ||
    isAugustStartPageEnabled ||
    isAugustAllStacksPageEnabled;

  const typeaheadProps = useTypeaheadSearch();

  // Match the sidebar.
  const globalNavIsMobile = useAtomValue(globalNavIsMobileAtom);

  const desktopExperience = EnvCtx.surface === 'desktop';

  const removePreviousQuery = (result: typeahead.ScoredPreviousQuery) => {
    removePreviousQueryFromCache(result).then(() =>
      typeaheadProps.reloadResults(),
    );
  };

  /**
   * When search is triggered from browser history or from home, we need to sync
   * the value in the typeahead with the query from browser history
   */
  const queryParams = useQueryParams();
  const { query: activeQuery, filters } = getSearchFromURLParams(queryParams);
  useEffect(() => {
    if (typeaheadProps.typeaheadQuery !== activeQuery) {
      const searchAttemptSession = searchAttemptSessionManager.getSession();
      let taggedQuery = false;
      if (searchAttemptSession) {
        const oldQuery = searchAttemptSession?.properties.query;
        const oldIsTypeAhead = searchAttemptSession?.properties.isTypeahead;
        if (activeQuery === oldQuery && oldIsTypeAhead) {
          taggedQuery = true;
        }
      }
      typeaheadProps.setTypeaheadQuery(activeQuery, taggedQuery);
    }
    // TODO: Fix up the deps properly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuery]);

  const onSearch = (query: string, searchFilters?: SearchFilter[]) => {
    typeaheadProps.setCanShowTypeahead(false);
    searchAttemptSessionManager.updateProperties({ searchSurface: 'top_nav' });
    publishEvent(SurveyEvent.SearchComplete);

    // Search automatically happens at /search_results
    // Apply filers from urls params unless filters manually provided
    navigate(generateSearchURL(query, searchFilters ?? filters));
  };

  const searchBar = (
    <SearchBarWithTypeahead
      {...typeaheadProps}
      removePreviousQuery={removePreviousQuery}
      onSearchSubmit={onSearch}
      shouldFocusOnRender
      fullWidth={desktopExperience}
      augustRevisionEnabled={augustRevisionEnabled}
      showBackground={isAugustStackPageEnabled && backgroundTheme !== undefined}
      pageScrolled={pageScrolled}
    />
  );

  return (
    <ThemeProvider overrides={backgroundTheme}>
      <ThemeContainer>
        {desktopExperience && !globalNavIsMobile ? (
          searchBar
        ) : (
          <div
            className={classNames(styles.container, {
              [styles.mobile]: globalNavIsMobile,
              [styles.augustRevision]: augustRevisionEnabled,
              [styles.showBackground]:
                isAugustStackPageEnabled && backgroundTheme !== undefined,
              [styles.scrollingBorder]: pageScrolled && !desktopExperience,
            })}
          >
            <div className={styles.leftContainer}>
              {globalNavIsMobile && (
                <IconButton
                  className={styles.noShrink}
                  shape="circular"
                  variant="transparent"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  aria-label={i18n.t(isCollapsed ? 'expand' : 'collapse')}
                >
                  <UIIcon src={ListViewLine} />
                </IconButton>
              )}
            </div>
            {globalNavIsMobile ? (
              <div
                tabIndex={0}
                className={styles.centerContainer}
                role="button"
                aria-label={i18n.t('home')}
                onClick={() => navigate('/')}
                onKeyDown={onKeyDownCommitFn(() => navigate('/'))}
              >
                <WordmarkLogo
                  size={32}
                  src={DashWordmark}
                  color="var(--dig-color__text__base)"
                />
                <BetaBadge excludeDfb />
              </div>
            ) : (
              searchBar
            )}
            {/* This will appear on the second row of the grid layout */}
            {globalNavIsMobile && (
              <div className={styles.mobileSearchBar}>{searchBar}</div>
            )}
          </div>
        )}
      </ThemeContainer>
    </ThemeProvider>
  );
};
