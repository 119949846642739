import { PAP_Click_PreviousSession } from '@mirage/analytics/events/types/click_previous_session';
import { PAP_Delete_PreviousSession } from '@mirage/analytics/events/types/delete_previous_session';
import { SessionsList } from '@mirage/mosaics/ComposeAssistant/components/sessions/SessionsList';
import { useComposeAnalyticsContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeAnalyticsContext';
import { useComposeSessionsContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeSessionsContext';
import { useComposeCurrentSessionContext } from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { memo, useMemo } from 'react';

export const ComposeSessionsPane = memo(() => {
  const { sessions, deleteSession } = useComposeSessionsContext();
  const { loadSession, currentSessionID } = useComposeCurrentSessionContext();
  const { logComposeEvent } = useComposeAnalyticsContext('compose_side_bar');
  const sortedSessions = useMemo(() => {
    // sort by most recently modified first
    return (sessions || [])
      .slice()
      .sort((a, b) => b.lastUpdated - a.lastUpdated);
  }, [sessions]);
  return (
    <SessionsList
      sessions={sortedSessions}
      currentSessionID={currentSessionID}
      onClickSession={(session) => {
        loadSession(session.id);
        logComposeEvent(PAP_Click_PreviousSession());
      }}
      onDeleteSession={(session) => {
        deleteSession(session.id);
        logComposeEvent(PAP_Delete_PreviousSession());
      }}
    />
  );
});
ComposeSessionsPane.displayName = 'ComposeSessionsPane';
