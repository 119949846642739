import { Chip } from '@dropbox/dig-components/chip';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Typeahead } from '@dropbox/dig-components/typeahead';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, SearchLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useDebounce } from '@mirage/shared/hooks/useDebounce';
import { AvatarMember } from '@mirage/stacks/ShareModal/Avatar';
import {
  SharingMember,
  SharingMemberKey,
  SharingStackPermission,
  SharingUserContactKey,
  StackPermission,
} from '@mirage/stacks/ShareModal/Types';
import {
  getSharingMemberIdentifier,
  sharingMemberKeysMatch,
  validateEmail,
} from '@mirage/stacks/ShareModal/Utils';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { PermissionsMenu } from './PermissionsMenu';
import styles from './ShareModal.module.css';

export const SharingTypeahead = ({
  stackMemberKeys,
  isSharing,
  excludedMembers,
  getSharingMembers,
  setInvitees,
  inviteesPermission = StackPermission.READ,
  setInviteesPermission,
  // Specifying onMemberSelected changes the mode in which the typeahead operates.
  // If onMemberSelected is specified, the typeahead will immediately call onMemberSelected
  // with the selected member and not add it to the selected members list.
  // Generally, one sets either this or setInvitees, but not both.
  onMemberSelected,
  allowExternalShare = false,
  showAddIconInResultRow = false,
  disableInvitePeopleViaEmail = false,
}: {
  stackMemberKeys: SharingMemberKey[];
  isSharing: boolean;
  excludedMembers: SharingMemberKey[];
  getSharingMembers: (searchText: string) => Promise<SharingMember[]>;
  setInvitees?: React.Dispatch<
    React.SetStateAction<(SharingMember | SharingUserContactKey)[]>
  >;
  inviteesPermission?: SharingStackPermission;
  setInviteesPermission?: React.Dispatch<
    React.SetStateAction<SharingStackPermission>
  >;
  onMemberSelected?: (member: SharingMember | SharingUserContactKey) => void;
  allowExternalShare?: boolean;
  showAddIconInResultRow?: boolean;
  disableInvitePeopleViaEmail?: boolean;
}) => {
  const [shareText, setShareText] = useState('');
  const [isTypeaheadOpen, setIsTypeaheadOpen] = useState(false);
  const [sharingContactsResults, setSharingContactsResults] = useState<
    SharingMember[]
  >([]);
  const [isSearchingSharingContacts, setIsSearchingSharingContacts] =
    useState(false);
  const [selectedSharingMembers, setSelectedSharingMembers] = useState<
    (SharingMember | SharingUserContactKey)[]
  >([]);

  const updateSharingContacts = async () => {
    setIsSearchingSharingContacts(true);
    const members = await getSharingMembers(shareText);
    const filteredMembers = members
      .filter((member) => {
        return (
          !stackMemberKeys.some((memberKey) =>
            sharingMemberKeysMatch(memberKey, member),
          ) &&
          !selectedSharingMembers.some((selectedMember) =>
            sharingMemberKeysMatch(selectedMember, member),
          ) &&
          !excludedMembers.some((memberKey) =>
            sharingMemberKeysMatch(memberKey, member),
          )
        );
      })
      .slice(0, 3);
    setSharingContactsResults(filteredMembers);
    setIsSearchingSharingContacts(false);
  };

  // Debounce request to update suggestions until user stops modifying it for 2s
  const debouncedUpdateSharingContacts = useDebounce(
    () => void updateSharingContacts(),
    500,
  );

  useEffect(() => {
    setInvitees?.(selectedSharingMembers);
  }, [setInvitees, selectedSharingMembers]);

  useEffect(() => {
    debouncedUpdateSharingContacts();
  }, [shareText, debouncedUpdateSharingContacts]);

  useEffect(() => {
    return () => {
      debouncedUpdateSharingContacts.cancel();
    };
  }, [debouncedUpdateSharingContacts]);

  const renderTypeaheadRow = useCallback(
    (result: SharingMember) => {
      return (
        <Typeahead.Row
          key={getSharingMemberIdentifier(result)}
          value={result}
          withTitle={result.displayName}
          withSubtitle={
            result['.tag'] === 'user'
              ? result.email
              : `${i18n.t('company_accesslevel')} · ${i18n.t('num_members', {
                  count: result.memberCount,
                })}`
          }
          withLeftAccessory={<AvatarMember member={result} hasNoOutline />}
          withRightAccessory={
            showAddIconInResultRow ? <UIIcon src={AddLine} /> : undefined
          }
        />
      );
    },
    [showAddIconInResultRow],
  );

  const selectMember = useCallback(
    (member: SharingMember | SharingUserContactKey): void => {
      setShareText('');

      if (onMemberSelected) {
        onMemberSelected(member);
        return;
      }

      setSelectedSharingMembers((currentMembers) => {
        if (
          currentMembers.some((currentMember) =>
            sharingMemberKeysMatch(currentMember, member),
          )
        ) {
          return currentMembers;
        }
        return [...currentMembers, member];
      });

      debouncedUpdateSharingContacts();
    },
    [onMemberSelected, setShareText, debouncedUpdateSharingContacts],
  );

  const deselectMember = useCallback((member: SharingMemberKey): void => {
    setSelectedSharingMembers((currentMembers) =>
      currentMembers.filter(
        (currentMember) => !sharingMemberKeysMatch(currentMember, member),
      ),
    );
  }, []);

  const findSuggestedMember = useCallback(
    (memberKey: SharingUserContactKey) => {
      const member = sharingContactsResults.find((result) =>
        sharingMemberKeysMatch(result, memberKey),
      );

      if (allowExternalShare) {
        // Only accept a validated email address if we're allowing external sharing
        return member || memberKey;
      }
      return member;
    },
    [sharingContactsResults, allowExternalShare],
  );

  const handleInputChange = useCallback(
    (value: string) => {
      const lastChar = value.slice(-1);

      if (!onMemberSelected && (lastChar === ' ' || lastChar === ',')) {
        const trimmedValue = value.replace(',', '').trim();
        setShareText(trimmedValue);
        if (validateEmail(trimmedValue)) {
          const member = findSuggestedMember({
            '.tag': 'user',
            email: trimmedValue,
          });
          if (member) {
            selectMember(member);
            return;
          }
        }
      }

      setShareText(value);
      debouncedUpdateSharingContacts();
    },
    [
      onMemberSelected,
      selectMember,
      setShareText,
      debouncedUpdateSharingContacts,
      findSuggestedMember,
    ],
  );

  const handleKeyDown: React.KeyboardEventHandler = useCallback(
    (e) => {
      if (onMemberSelected) {
        return;
      }

      let member: SharingMember | SharingUserContactKey | undefined;

      switch (e.key) {
        case 'Backspace':
          if (shareText === '') {
            setSelectedSharingMembers((currentMembers) => {
              if (currentMembers.length === 0) {
                return currentMembers;
              }
              const lastMember = currentMembers.slice(-1)[0];
              if ('displayName' in lastMember) {
                debouncedUpdateSharingContacts();
              }
              return currentMembers.slice(0, -1);
            });
          }
          break;
        case 'Enter':
          if (!validateEmail(shareText)) {
            // Searches that resolve to a suggestion will still be
            // selected by the typeahead.
            break;
          }
          member = findSuggestedMember({ '.tag': 'user', email: shareText });
          if (member) {
            selectMember(member);
          }
          break;
      }
    },
    [
      onMemberSelected,
      shareText,
      debouncedUpdateSharingContacts,
      selectMember,
      findSuggestedMember,
    ],
  );

  return (
    <Typeahead.Wrapper
      onSelection={selectMember}
      onToggle={({ isOpen }) => setIsTypeaheadOpen(isOpen)}
      shouldHighlightFirstRow
      isPortaled={false}
      closeOnSelection={false}
    >
      {({ getTriggerProps, getContentProps }) => (
        <>
          <TextInput.Container
            size="large"
            isTransparent
            className={styles.typeaheadInputContainer}
            {...getTriggerProps()}
          >
            <TextInput.Accessory position="start">
              <UIIcon src={SearchLine} className={styles.searchIcon} />
            </TextInput.Accessory>
            <TextInput.ChipsContainer>
              {onMemberSelected ||
                selectedSharingMembers.map(
                  (sharingMember: SharingMember | SharingMemberKey) => (
                    <Chip
                      key={getSharingMemberIdentifier(sharingMember)}
                      size="small"
                      variant={
                        sharingMember['.tag'] === 'user' &&
                        !validateEmail(sharingMember.email)
                          ? 'warning'
                          : undefined
                      }
                      onDelete={() => deselectMember(sharingMember)}
                    >
                      {'displayName' in sharingMember && (
                        <Chip.AvatarAccessory>
                          <AvatarMember
                            member={sharingMember as SharingMember}
                            avatarSize="small"
                          />
                        </Chip.AvatarAccessory>
                      )}
                      <Chip.Content>
                        {'displayName' in sharingMember
                          ? sharingMember.displayName
                          : (sharingMember as SharingUserContactKey).email}
                      </Chip.Content>
                    </Chip>
                  ),
                )}
              <TextInput.Input
                onChange={(e) => handleInputChange(e.currentTarget.value)}
                value={isSharing ? i18n.t('sharing_ellipses') : shareText}
                placeholder={
                  selectedSharingMembers.length === 0
                    ? i18n.t('invite_someone_via_email')
                    : undefined
                }
                disabled={isSharing || disableInvitePeopleViaEmail}
                onKeyDown={handleKeyDown}
                data-testid="Stacks-shareStackByEmailInput"
              />
            </TextInput.ChipsContainer>
            {!onMemberSelected && (
              <div
                className={classNames(
                  styles.typeaheadPermissionsMenuContainer,
                  {
                    [styles.hidden]:
                      !isTypeaheadOpen && selectedSharingMembers.length === 0,
                  },
                )}
              >
                <PermissionsMenu
                  sharePermission={inviteesPermission}
                  onSelectSharePermissions={(permission) =>
                    setInviteesPermission?.(permission)
                  }
                  buttonProps={{
                    variant: 'borderless',
                    hasNoUnderline: true,
                    size: 'small',
                  }}
                  dfb
                />
              </div>
            )}
          </TextInput.Container>
          {/* TODO: Remove this <div> once DSR-591 is resolved and apply the
                    className direclty to the Typeahead.Container */}
          <div
            className={
              onMemberSelected ? undefined : styles.typeaheadContainerContainer
            }
          >
            <Typeahead.Container
              isEmptyQuery={
                !onMemberSelected && sharingContactsResults.length === 0
              }
              emptyPrompt={<EmptyPrompt shareText={shareText} />}
              loading={isSearchingSharingContacts}
              {...getContentProps()}
            >
              {onMemberSelected ? undefined : (
                <div className={styles.typeaheadContainerHeading}>
                  <Text variant="label" color="subtle" size="small" isBold>
                    {i18n.t('suggested_invites')}
                  </Text>
                </div>
              )}
              <Typeahead.Results
                results={sharingContactsResults}
                renderRow={renderTypeaheadRow}
              />
            </Typeahead.Container>
          </div>
        </>
      )}
    </Typeahead.Wrapper>
  );
};

const EmptyPrompt = ({ shareText }: { shareText: string }) => {
  if (!validateEmail(shareText)) {
    return (
      <Typeahead.EmptyPrompt
        placeholderText={i18n.t('sharing_typeahead_empty_hint')}
      />
    );
  }
  return (
    <Typeahead.Prompt>
      <Text>{i18n.t('sharing_typeahead_completion_hint')}</Text>
    </Typeahead.Prompt>
  );
};
