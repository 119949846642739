import { Text } from '@dropbox/dig-components/typography';
import { highlightTokenizer } from '@mirage/shared/util/stop-words';
import ReactHighlightWords from 'react-highlight-words';
import styles from './SnippetText.module.css';

const Bold: React.FC = ({ children }) => (
  <Text tagName="span" isBold={true} color="subtle">
    {children}
  </Text>
);

// TEMPORARY hack:
// The server only returns highlights for lexical results.
// We're going to fill in the gaps on the client by highlighting text in semantic results as well
// This means we need to remove the <em> tags from the server response to start from scratch.
// In the future we may let the server handle all of this
// https://jira.dropboxer.net/browse/OTCSI-1427
function removeEmTags(input: string): string {
  return input.replace(/<\/?em>/g, '');
}

type SnippetTextProps = {
  textToHighlight: string;
  query?: string;
};

export const SnippetText = ({
  textToHighlight,
  query = '',
}: SnippetTextProps) => {
  const sanitizedTextToHighlight = removeEmTags(textToHighlight);
  const searchWords = highlightTokenizer(query);

  return (
    <Text color={'subtle'}>
      <ReactHighlightWords
        className={styles.highlightContainer}
        searchWords={searchWords}
        autoEscape={true}
        textToHighlight={sanitizedTextToHighlight + '...'}
        caseSensitive={false}
        highlightTag={Bold}
      />
    </Text>
  );
};
