import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import { tagged } from '@mirage/service-logging';
import { useDashActionSurface } from '@mirage/shared/hooks/useDashActionSurface';
import React, { createContext, useCallback, useContext } from 'react';

const logger = tagged('ComposeAnalyticsContext');

export interface ComposeAnalyticsContextInterface {
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
}

export const ComposeAnalyticsContext =
  createContext<ComposeAnalyticsContextInterface | null>(null);

export const useComposeAnalyticsContext = (
  actionSurfaceComponent: ActionSurfaceComponent,
) => {
  const context = useContext(ComposeAnalyticsContext);
  if (!context) {
    throw new Error(
      'useComposeAnalyticsContext must be used within a ComposeAnalyticsContextProvider',
    );
  }

  return {
    logComposeEvent: (
      event: PAPEvent,
      overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
    ) => {
      const finalActionSurfaceComponent =
        overrides?.actionSurfaceComponent || actionSurfaceComponent;
      context.logComposeEvent({
        ...event,
        properties: {
          ...event.properties,
          actionSurfaceComponent: finalActionSurfaceComponent,
        },
      });
    },
  };
};

interface ComposeAnalyticsContextProviderProps {
  children: React.ReactNode;
}

export const ComposeAnalyticsContextProvider = ({
  children,
}: ComposeAnalyticsContextProviderProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { dashActionSurface } = useDashActionSurface();

  const logComposeEvent = useCallback(
    (event: PAPEvent) => {
      if (!dashActionSurface) {
        logger.warn('dashActionSurface not set');
        return;
      }

      const mergedProperties = {
        ...event.properties,
        actionSurface: dashActionSurface,
        dashActionSurface,
        featureLine: 'assist' as FeatureLine,
      };

      reportPapEvent({
        class: event.class,
        action: event.action,
        object: event.object,
        properties: mergedProperties,
      });

      logger.info(
        `Logged event: ${event.action}.${event.object} ${JSON.stringify(
          mergedProperties,
        )}`,
      );
    },
    [dashActionSurface, reportPapEvent],
  );

  return (
    <ComposeAnalyticsContext.Provider value={{ logComposeEvent }}>
      {children}
    </ComposeAnalyticsContext.Provider>
  );
};
