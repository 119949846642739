import { PAP_Click_AddSourcesButton } from '@mirage/analytics/events/types/click_add_sources_button';
import { PAP_Interact_CancelSaveSources } from '@mirage/analytics/events/types/interact_cancel_save_sources';
import { ContextInputMessages } from '@mirage/mosaics/ComposeAssistant/components/chat/ContextInputMessages';
import { ConversationFollowUpSuggestions } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationFollowUpSuggestions';
import { ConversationInput } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationInput';
import { ConversationMessages } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationMessages';
import {
  ClickableEmptyChatInstructionsOption,
  EmptyChatInstructions,
} from '@mirage/mosaics/ComposeAssistant/components/chat/EmptyChatInstructions';
import { AddSourcesModal } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/AddSourcesModal';
import { useComposeAnalyticsContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeAnalyticsContext';
import { useComposeCurrentSessionContext } from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { openURL } from '@mirage/service-platform-actions';
import {
  ComposeAssistantConversationMessage,
  ComposeAssistantConversationMessageMessage,
  getFirstMarkdownArtifact,
} from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import { memo, useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const ComposeConversation = memo(() => {
  const {
    messagesHistory,
    sources,
    addSource,
    removeSource,
    artifacts,
    setDraftConfig,
    sourcesContentCache,
    addRawMessage,
    postUserMessage,
    inputContext,
    setInputContext,
    currentSessionID,
    isWaitingForResponse,
  } = useComposeCurrentSessionContext();
  const { logComposeEvent } = useComposeAnalyticsContext('compose_chat_pane');
  const markdownArtifact = useMemo(
    () => getFirstMarkdownArtifact(artifacts),
    [artifacts],
  );
  const handleCompleteContextInputs = useCallback(() => {
    if (messagesHistory.length > 0) {
      // no-op if already started talking
      return;
    }
    const text = markdownArtifact?.draftConfig.contentType
      ? i18n.t('compose_assistant_message_with_content_type', {
          contentType: markdownArtifact.draftConfig.contentType,
        })
      : i18n.t('compose_assistant_message_generic');
    addRawMessage({
      type: 'message',
      role: 'assistant',
      text,
      ts: Date.now(),
    });
  }, [
    addRawMessage,
    markdownArtifact?.draftConfig.contentType,
    messagesHistory.length,
  ]);
  const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useState(false);
  const handleClickAdd = useCallback(() => {
    setIsAddSourceModalOpen(true);
    logComposeEvent(
      PAP_Click_AddSourcesButton({
        actionType: 'chat_pane',
      }),
    );
  }, [logComposeEvent]);
  const handleCloseAddSourcesModal = useCallback(() => {
    setIsAddSourceModalOpen(false);
    logComposeEvent(PAP_Interact_CancelSaveSources(), {
      actionSurfaceComponent: 'compose_source_modal',
    });
  }, [logComposeEvent]);
  const handleDoneAddSoucesModal = useCallback(() => {
    setIsAddSourceModalOpen(false);
    if (messagesHistory.length === 0) handleCompleteContextInputs();
  }, [handleCompleteContextInputs, messagesHistory.length]);

  const showEmptyChatInstructions =
    messagesHistory.length === 0 && markdownArtifact === undefined;

  const startingMessageNode = showEmptyChatInstructions ? (
    <ComposeEmptyChatInstructions />
  ) : (
    <ContextInputMessages
      key={currentSessionID} // reset inputs when session changes
      handleClickAdd={handleClickAdd}
      sources={sources}
      sourcesContentCache={sourcesContentCache}
      removeSource={removeSource}
      draftConfig={markdownArtifact?.draftConfig || {}}
      setDraftConfig={setDraftConfig}
      onCompleteInputs={() => {
        if (messagesHistory.length === 0) {
          handleCompleteContextInputs();
        }
      }}
      logComposeEvent={logComposeEvent}
    />
  );
  const isLastMessageFollowUpSuggestions =
    messagesHistory.length > 0 &&
    messagesHistory[messagesHistory.length - 1].type === 'message' &&
    (
      messagesHistory[
        messagesHistory.length - 1
      ] as ComposeAssistantConversationMessageMessage
    ).followUpSuggestions?.length;
  return (
    <>
      <ConversationMessages
        isLastMessageFollowUpSuggestions={!!isLastMessageFollowUpSuggestions}
        startingMessageNode={startingMessageNode}
        messages={messagesHistory}
        isWaitingForResponse={isWaitingForResponse}
        onRemoveSource={removeSource}
      />
      {isLastMessageFollowUpSuggestions && (
        <ConversationFollowUpSuggestions
          message={messagesHistory[messagesHistory.length - 1]}
          onClickFollowUpSuggestion={(suggestion: string) => {
            postUserMessage({
              text: suggestion,
              isFollowUpSuggestion: true,
            });
          }}
        />
      )}
      <ConversationInput
        onSubmit={(text) => {
          if (getUserMessages(messagesHistory).length === 0) {
            postUserMessage({
              text,
              mustGenerateDraft: true,
              mustIncludeSourceContents: true,
            });
          } else {
            postUserMessage({
              text,
            });
          }
        }}
        inputContext={inputContext}
        setInputContext={setInputContext}
        logComposeEvent={logComposeEvent}
        handleClickAdd={handleClickAdd}
        sources={sources}
        messages={messagesHistory}
      />
      {isAddSourceModalOpen && (
        <AddSourcesModal
          sources={sources}
          sourcesContentCache={sourcesContentCache}
          addSource={addSource}
          removeSource={removeSource}
          onRequestClose={handleCloseAddSourcesModal}
          onClickDone={handleDoneAddSoucesModal}
          logComposeEvent={logComposeEvent}
        />
      )}
    </>
  );
});
ComposeConversation.displayName = 'ComposeConversation';

export const ComposeEmptyChatInstructions = memo(() => {
  const { addArtifact } = useComposeCurrentSessionContext();
  const handleClickStartingOption = useCallback(
    (option: ClickableEmptyChatInstructionsOption) => {
      switch (option) {
        case 'write':
          addArtifact({
            type: 'markdown_draft',
            id: uuidv4(),
            markdownContent: '',
            draftConfig: {},
          });
          return;
        case 'request_capabilities':
          openURL('https://forms.gle/8NrfeQ58mMAzYr916');
          return;
        default:
          option satisfies never;
          throw new Error(`Unknown option: ${option}`);
      }
    },
    [addArtifact],
  );
  return <EmptyChatInstructions onClickOption={handleClickStartingOption} />;
});
ComposeEmptyChatInstructions.displayName = 'ComposeEmptyChatInstructions';

function getUserMessages(
  messages: ComposeAssistantConversationMessage[],
): ComposeAssistantConversationMessage[] {
  return messages.filter((message) => message.role === 'user');
}
