import { Dropbox, DropboxAuth } from '@dropbox/api-v2-client';
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { tagged } from '@mirage/service-logging';
import { namespace } from '@mirage/service-operational-metrics';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import WithDefaults from '@mirage/storage/with-defaults';
import WithOverrides from '@mirage/storage/with-overrides';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { RedirectState } from '../types';

import type { DropboxOptions, users } from '@dropbox/api-v2-client';
import type { LogoutServiceConsumerContract } from '@mirage/service-logout';
import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

const logger = tagged('service-auth');

export type DropboxAuthData = {
  sessionId: string;
  codeVerifier: string;
  connectionState: {
    service: string;
    context: string;
  };
};

export type DropboxAuthQuery = {
  state: string;
  code: string;
};

export enum AuthenticationError {
  AuthenticationNotInProgress = 'authentication-not-in-progress',
  CouldNotRefreshToken = 'could-not-refresh-token',
  CouldNotVerifyToken = 'could-not-verify-token',
  InvalidResponse = 'invalid-response',
  RefreshTokenMissing = 'refresh-token-missing',
}

export enum AuthenticationStatus {
  None = 'none',
  InProgress = 'in-progress',
  Initializing = 'initializing',
  Error = 'error',
  Finishing = 'finishing',
  Complete = 'complete',
}

export type AuthenticationData = {
  accessToken: string;
  refreshToken: string;
  accessTokenExpiresAt: number;
  uid: string | undefined;
  isExchangedToken?: boolean;
};

export type AccountIds = {
  uid: users.FullAccount['uid'];
  account_id: users.FullAccount['account_id'];
};

export type Service = Awaited<ReturnType<typeof authentication>>;

type LoginRedirectConfiguration = {
  redirectToPathURLParam: string;
  rootRoutePath: string;
  loginRoutePath: string;
};

export type DropboxAuthenticationConfiguration = {
  clientId: string;
  domain?: string;
  clientSecret?: string;
  oauthRedirectURL: string;
  openURL: (url: string) => void;
  loginRedirectConfig?: LoginRedirectConfiguration;
  shouldUseStageBackend?: boolean;
};

export type AuthenticationStorage = {
  installId: string;
  codeVerifier: string | undefined;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  accessTokenExpiresAt: number | undefined;
  uid: string | undefined;
  isExchangedToken?: boolean;
  currentAccount: users.FullAccount | undefined;
  shouldUseStageBackend: boolean;
  forceReauthentication: boolean;
  sessionId: string;
  shouldTraceRequests: boolean;
};

export type AuthenticationState = {
  status: AuthenticationStatus;
  error?: AuthenticationError;
};

// Typings for `dbx.getAccessTokenFromCode` are inaccurate, so cast them to this
type ShortLivedTokenDataPayloadRaw = {
  access_token: string;
  account_id: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
  uid: number;
};

const REFRESH_TOKEN_TIMEOUT = 10_000; // ms

// temporary; used for hashing values to compare across logs
const LOGGING_UUID_NAMESPACE = '6d70c04e-6bc8-43d1-b936-e940c80ef131';
function hash(value: string): string {
  return uuidv5(value, LOGGING_UUID_NAMESPACE);
}

const metrics = namespace('authentication');

export default async function authentication(
  adapter: KVStorage<AuthenticationStorage>,
  config: DropboxAuthenticationConfiguration,
  logoutService: LogoutServiceConsumerContract,
  onError?: (e: unknown) => void,
) {
  const sessionId = uuidv4();
  const overridden = new WithOverrides(adapter, {
    sessionId,
  });

  logger.debug('starting auth service');
  const storage = new WithDefaults<AuthenticationStorage>(overridden, {
    // persistent install identifier
    installId: uuidv4(),
    sessionId, // no-op, but required for shape completeness
    // transient code verification for ongoing oauth
    codeVerifier: undefined,
    // authenticated user information
    accessToken: undefined,
    refreshToken: undefined,
    accessTokenExpiresAt: undefined,
    uid: undefined,
    currentAccount: undefined,
    shouldUseStageBackend: config.shouldUseStageBackend || false,
    forceReauthentication: false,
    shouldTraceRequests: false,
  });

  const state$ = new rx.Subject<AuthenticationState>();
  const account$ = new rx.ReplaySubject<users.FullAccount | undefined>(1);

  // XXX: do not remove this, reference the comment colocated with the fn call
  logger.debug('validating locally stored token values');
  await validateStoredTokenValues();

  // register listener for auth status changes and propagate updated user
  // information to external listeners
  rx.merge(
    state$.pipe(
      op.filter((res) => {
        return res.status === AuthenticationStatus.Complete;
      }),
      op.mergeMap(() => rx.from(getCurrentAccount())),
    ),
    rx.from(getCurrentAccount()),
  ).subscribe(account$);

  const dbx = new DropboxAuth({
    clientId: config.clientId,
    domain: config.domain,
    clientSecret: config.clientSecret,
  });

  async function getShouldUseStageBackend() {
    return await storage.get('shouldUseStageBackend');
  }

  async function getShouldTraceRequests() {
    return await storage.get('shouldTraceRequests');
  }

  async function setShouldUseStageBackend(shouldUseStageBackend: boolean) {
    await storage.set('shouldUseStageBackend', shouldUseStageBackend);
    // refresh current account to use new dropbox api client
    await getCurrentAccount(true);
    return shouldUseStageBackend;
  }

  async function setShouldTraceRequests(shouldTraceRequests: boolean) {
    await storage.set('shouldTraceRequests', shouldTraceRequests);
    return shouldTraceRequests;
  }

  // No need to persist in store.
  let isFreshLoginFlag = false;

  /**
   * Tracks whether this is a fresh login or not, for metrics reporting.
   * As long as the user does not refresh the webpage/app after logging in,
   * this will remain true. Once the user refreshes, this will be false.
   */
  async function isFreshLogin() {
    return isFreshLoginFlag;
  }

  /** Allow the user to reset the value. */
  async function setIsFreshLogin(value: boolean) {
    isFreshLoginFlag = value;
  }

  //
  // externally managed auth tokens
  //----------------------------------------------------------------------------
  async function setManagedAccessToken(accessToken: string): Promise<void> {
    logger.debug('setManagedAccessToken()', hash(accessToken));
    await storage.set('accessToken', accessToken);
    await storage.set('refreshToken', undefined);
    await storage.set('accessTokenExpiresAt', 0);
    await storage.set('uid', undefined);

    emitAuthenticationState({
      status: AuthenticationStatus.Complete,
    });
  }

  //
  // entry point for initiating oauth w/ dbx
  //----------------------------------------------------------------------------
  async function authenticate(redirectState: RedirectState | null) {
    metrics.counter('oauth/start', 1);
    logger.debug('authenticate()', redirectState);
    emitAuthenticationState({ status: AuthenticationStatus.InProgress });
    const url = await getAuthenticationURL(redirectState);
    logger.debug('authenticate() - generated auth URL', hash(url));
    const code = dbx.getCodeVerifier();
    await storage.set('codeVerifier', code);
    logger.debug('authenticate() - opening auth URL');
    config.openURL(url);
  }

  //
  // re-entry point to handle oauth redirect url flow
  //----------------------------------------------------------------------------
  async function exchangeCodeForToken(
    code: string,
    codeVerifier?: string | null,
  ): Promise<boolean> {
    logger.debug('exchangeCodeForToken()', hash(code));
    metrics.counter('oauth/exchange', 1);
    emitAuthenticationState({
      status: AuthenticationStatus.Finishing,
    });

    // The API client will ignore the codeVerifier unless we wipe out the
    // client secret here.
    dbx.setClientSecret(undefined as unknown as string);

    if (codeVerifier) {
      // Code verifier provided from URL param, used for Catapult login only.
      logger.debug('set code verifier from URL param (Catapult login)');
      dbx.setCodeVerifier(codeVerifier);
    } else {
      // Code verifier provided from local storage, normal flow for oauth
      // initiated from the webapp itself.
      logger.debug('got code verifier from local storage (normal login)');
      const verifier = await storage.get('codeVerifier');
      if (!verifier) {
        logger.error(
          'exchangeCodeForToken() - received oauth code but missing code verifier',
        );
        emitAuthenticationState({
          status: AuthenticationStatus.Error,
          error: AuthenticationError.AuthenticationNotInProgress,
        });
        return false;
      }

      dbx.setCodeVerifier(verifier);
    }

    try {
      logger.debug(
        'exchangeCodeForToken() - making request to dbx for access token',
      );
      const response = await dbx.getAccessTokenFromCode(
        config.oauthRedirectURL,
        code,
      );

      if (response.status !== 200) {
        logger.error(
          'exchangeCodeForToken() - could not verify token. status != 200',
          response,
        );
        metrics.counter('oauth/exchange/status', 1, { status: 'error' });
        emitAuthenticationState({
          status: AuthenticationStatus.Error,
          error: AuthenticationError.InvalidResponse,
        });
        return false;
      }

      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        expires_in: expiresIn,
        uid,
      } = response.result as ShortLivedTokenDataPayloadRaw;
      const expiresAt = new Date(Date.now() + expiresIn * 1000);

      logger.debug('exchangeCodeForToken() - got token response', {
        accessToken: hash(accessToken),
        refreshToken: hash(refreshToken),
        expiresAt,
        uid,
      });

      // update our dbx auth instance
      dbx.setAccessToken(accessToken);
      dbx.setRefreshToken(refreshToken);
      dbx.setAccessTokenExpiresAt(expiresAt);

      // write authentication information to storage
      await migrateFromExternalManagement({
        accessToken,
        refreshToken,
        accessTokenExpiresAt: expiresAt.getTime(),
        uid: uid.toString(),
      });

      metrics.counter('oauth/exchange/status', 1, { status: 'success' });

      logger.debug('exchangeCodeForToken() - completed code exchange');
      emitAuthenticationState({
        status: AuthenticationStatus.Complete,
      });

      await setIsFreshLogin(true);
      return true;
    } catch (e) {
      onError?.(e);
      metrics.counter('oauth/exchange/status', 1, { status: 'error' });
      logger.error('failed to validate incoming oauth code', e);
      emitAuthenticationState({
        status: AuthenticationStatus.Error,
        error: AuthenticationError.CouldNotVerifyToken,
      });
      return false;
    }
  }

  //
  // listening for changes within the auth pipeline
  //----------------------------------------------------------------------------
  function listen(): Observable<AuthenticationState> {
    return state$.asObservable();
  }

  function listenForAccount(): Observable<users.FullAccount | undefined> {
    return account$.asObservable();
  }

  function listenForAccountIds(): Observable<undefined | AccountIds> {
    return account$.pipe(
      op.distinctUntilChanged((previous, current) => {
        // avoid any logic below if we did not have an authed state change
        if (!previous && !current) return true;

        // allow auth state changes to propagate externally
        if (!previous || !current) return false;

        // for cached values, perform key-based equality checking on previous
        // and new account ids
        return (
          previous.account_id === current.account_id &&
          previous.uid === current.uid
        );
      }),
      op.map((value) =>
        value
          ? {
              uid: value.uid,
              account_id: value.account_id,
            }
          : value,
      ),
    );
  }

  //
  // external status indicators and state accessors
  //----------------------------------------------------------------------------
  async function getAccessToken(
    mode?: 'ignoreErrors',
  ): Promise<string | undefined> {
    if (!(await checkAndRefreshAccessToken(mode))) {
      return undefined;
    }
    return storage.get('accessToken');
  }

  async function getInstallId(): Promise<string> {
    return storage.get('installId');
  }

  async function getSessionId(): Promise<string> {
    return storage.get('sessionId');
  }

  async function setInstallId(installId: string): Promise<void> {
    return storage.set('installId', installId);
  }

  // Combine all concurrent requests.
  const getCurrentAccountCombined = getCombineAsyncRequestsFunc(
    async (apiClient: Dropbox) => {
      return await apiClient.usersGetCurrentAccount();
    },
  );

  // Refresh the account info at most once if the cached account was used.
  let refreshedAccount = false;
  function refreshAccountOnce() {
    if (refreshedAccount) return;

    setTimeout(() => {
      logger.info(
        'refreshAccountOnce() - timeout fired, refreshedAccount=%s',
        refreshedAccount ? 'true' : 'false',
      );
      if (!refreshedAccount) {
        getCurrentAccount(true);
      }
    }, 5000);
  }

  async function getCurrentAccount(
    refresh = false,
  ): Promise<users.FullAccount | undefined> {
    if (!refresh) {
      const account = await storage.get('currentAccount');
      logger.debug('getCurrentAccount() - has stored account =', !!account);
      if (account) {
        logger.debug('getCurrentAccount() - calling to refresh account');
        refreshAccountOnce();
        return account;
      }
    }

    try {
      refreshedAccount = true;
      const api = await getDropboxAPIInstanceOrNull();
      if (!api) {
        logger.debug(
          'getCurrentAccount() - was not able to get a dropbox api instance',
        );
        return undefined;
      }
      logger.debug('getCurrentAccount() - fetching current account');
      const response = await getCurrentAccountCombined(api);

      const fullAccount = response.result;
      await storage.set('currentAccount', fullAccount);
      account$.next(fullAccount);
      return fullAccount;
    } catch (e) {
      onError?.(e);
      logger.error('failed to get current user account', e);
    }
  }

  async function getUpdaterId(): Promise<string | undefined> {
    try {
      const api = await getDropboxAPIInstance();
      return (await api.squirrelLink({})).result.updater_id;
    } catch (e) {
      onError?.(e);
      logger.error('failed to get updater ID', e);
      return;
    }
  }

  async function revokeAccessToken(): Promise<void> {
    try {
      const api = await getDropboxAPIInstanceOrNull();
      logger.debug('revokeAccessToken() - revoking access token');
      await api?.authTokenRevoke();
    } catch (e) {
      logger.info(`Ignoring error in authTokenRevoke:`, e);
    }
  }

  async function logout(): Promise<void> {
    // Exchanged tokens should not be revoked because once revoked, they will
    // stop working in future token exchanges.
    const isExchangedToken = await storage.get('isExchangedToken');
    if (isExchangedToken) {
      logger.info(`Not revoking exchanged access token on logout`);
    } else {
      await revokeAccessToken();
    }

    metrics.counter('logout', 1);
    logger.info('logout()');
    // Preserve the installId while clearing user authentication
    // and session state.
    const installId = await storage.get('installId');
    await storage.clear();
    await storage.set('installId', installId);
    emitAuthenticationState({ status: AuthenticationStatus.None });
    account$.next(undefined);
  }

  //
  // helper methods
  //----------------------------------------------------------------------------
  async function migrateFromExternalManagement({
    accessToken,
    refreshToken,
    accessTokenExpiresAt,
    uid,
    isExchangedToken,
  }: AuthenticationData): Promise<void> {
    logger.debug('migrateFromExternalManagement()', {
      accessToken: hash(accessToken),
      refreshToken: hash(refreshToken),
      accessTokenExpiresAt,
      uid,
      isExchangedToken,
    });

    await storage.set('accessToken', accessToken);
    await storage.set('refreshToken', refreshToken);
    await storage.set('accessTokenExpiresAt', accessTokenExpiresAt);
    await storage.set('uid', uid);
    await storage.set('isExchangedToken', isExchangedToken);
  }

  async function resetAuthenticationData(): Promise<void> {
    logger.debug('resetAuthenticationData()');

    await storage.set('accessToken', undefined);
    await storage.set('refreshToken', undefined);
    await storage.set('accessTokenExpiresAt', undefined);
    await storage.set('uid', undefined);
    await storage.set('isExchangedToken', undefined);
  }

  //
  // Get authentication data
  //----------------------------------------------------------------------------
  async function getAuthenticationData(): Promise<
    AuthenticationData | undefined
  > {
    const accessToken = await storage.get('accessToken');
    const refreshToken = await storage.get('refreshToken');
    const accessTokenExpiresAt = await storage.get('accessTokenExpiresAt');
    const uid = await storage.get('uid');
    const isExchangedToken = await storage.get('isExchangedToken');

    if (!accessToken || !refreshToken || accessTokenExpiresAt === undefined) {
      return undefined;
    }

    return {
      accessToken,
      refreshToken,
      accessTokenExpiresAt,
      uid,
      isExchangedToken,
    };
  }

  // XXX: this service was intended to fully manage the lifecycle of dbx oauth
  // tokens but exposes means to inject an access token into the service so that
  // dependent services can consume auth. to ensure that we properly clean up
  // in the event that we transition, we will only maintain storage state when
  // we have the required fields stored locally
  async function validateStoredTokenValues(): Promise<void> {
    logger.debug('validateStoredTokenValues()');
    const [accessToken, refreshToken, accessTokenExpiresAt] = await Promise.all(
      [
        storage.get('accessToken'),
        storage.get('refreshToken'),
        storage.get('accessTokenExpiresAt'),
      ],
    );

    // if we have no access token, we'll assume we're in a cleared state
    if (!accessToken) {
      logger.debug(
        'validateStoredTokenValues() - no access token found, doing nothing',
      );
      return;
    }

    // if we have an access token but no refresh token, wipe our state
    if (!refreshToken) {
      logger.debug(
        'validateStoredTokenValues() - access token found but missing refresh token',
      );
      return logout();
    }

    // if we have an odd expiry time, also wipe our local state
    if (!accessTokenExpiresAt || accessTokenExpiresAt < 0) {
      logger.debug(
        'validateStoredTokenValues() - invalid access token expiry found',
      );
      return logout();
    }
  }

  function emitAuthenticationState(state: AuthenticationState) {
    logger.debug(`emitAuthenticationState ${JSON.stringify(state)}`);
    state$.next(state);
  }

  /** Returns true if the refresh succeeded. */
  async function refreshAccessToken(mode?: 'ignoreErrors'): Promise<boolean> {
    metrics.counter('access-token/refresh', 1);
    logger.debug('refreshAccessToken()');
    const refreshToken = await storage.get('refreshToken');
    if (!refreshToken) {
      if (mode === 'ignoreErrors') {
        logger.debug(
          'refreshAccessToken() - no refresh token found in storage - ok to ignore',
        );
        return false;
      }

      const accessTokenExpiresAt = await storage.get('accessTokenExpiresAt');
      if (!accessTokenExpiresAt) {
        logger.debug(
          'refreshAccessToken() - no refresh token found in storage - not needed',
        );
        return false;
      }

      logger.debug('refreshAccessToken() - no refresh token found in storage');
      emitAuthenticationState({
        status: AuthenticationStatus.Error,
        error: AuthenticationError.RefreshTokenMissing,
      });
      throw new Error('No refresh token found!');
    }

    try {
      dbx.setRefreshToken(refreshToken);

      await Promise.race([
        // XXX: lib typings are incorrect, this _is_ async
        dbx.checkAndRefreshAccessToken(),
        new Promise((_, reject) =>
          setTimeout(() => {
            reject(new Error('Request to refresh access token timed out'));
          }, REFRESH_TOKEN_TIMEOUT),
        ),
      ]);

      logger.debug('refreshAccessToken() - setting token data locally');
      await storage.set('accessToken', dbx.getAccessToken());
      await storage.set('refreshToken', dbx.getRefreshToken());
      await storage.set(
        'accessTokenExpiresAt',
        new Date(dbx.getAccessTokenExpiresAt()).getTime(),
      );

      metrics.counter('access-token/refresh/status', 1, { status: 'success' });

      emitAuthenticationState({
        status: AuthenticationStatus.Complete,
      });
      return true;
    } catch (e) {
      onError?.(e);
      logger.error('refreshAccessToken() - failed to refresh access token', e);
      metrics.counter('access-token/refresh/status', 1, { status: 'error' });
      emitAuthenticationState({
        status: AuthenticationStatus.Error,
        error: AuthenticationError.CouldNotRefreshToken,
      });
      return false;
    }
  }

  const checkAndRefreshAccessToken = getCombineAsyncRequestsFunc(
    async (mode?: 'ignoreErrors') => {
      return checkAndRefreshAccessTokenActual(mode);
    },
  );

  /** Returns true if the token is refreshed, or does not need a refresh. */
  async function checkAndRefreshAccessTokenActual(
    mode?: 'ignoreErrors',
  ): Promise<boolean> {
    const accessToken = await storage.get('accessToken');
    if (!accessToken) {
      logger.debug('checkAndRefreshAccessToken() - access token missing');
      return false;
    }
    const expiresAt = await storage.get('accessTokenExpiresAt');
    if (!expiresAt) {
      logger.debug(
        'checkAndRefreshAccessToken() - no access token expiry -> valid for managed tokens',
      );
      return true;
    }
    if (!isTokenExpired(expiresAt)) {
      logger.debug(
        'checkAndRefreshAccessToken() - access token is not expired',
      );
      return true;
    }

    logger.debug('checkAndRefreshAccessToken() - refreshing access token');
    return await refreshAccessToken(mode);
  }

  async function getDropboxAPIInstance() {
    const parameters = await getDropboxInitializationParameters();
    logger.debug('getDropboxAPIInstance() - returning dropbox instance');
    return new Dropbox(parameters);
  }

  async function getDropboxAPIInstanceOrNull() {
    const parameters = await getDropboxInitializationParametersOrNull();
    logger.debug(
      'getDropboxAPIInstanceOrNull()',
      parameters ? 'parameters ok' : 'parameters missing',
    );
    return parameters ? new Dropbox(parameters) : null;
  }

  async function getDropboxInitializationParameters(): Promise<DropboxOptions> {
    return nonNil(
      await getDropboxInitializationParametersOrNull(),
      'access token',
    );
  }

  async function getDropboxInitializationParametersOrNull(): Promise<DropboxOptions | null> {
    logger.debug('getDropboxInitializationParametersOrNull()');
    const [accessToken, dropboxAppAuthInitializationParameters] =
      await Promise.all([
        getAccessToken('ignoreErrors'),
        getDropboxAppAuthInitializationParameters(),
      ]);

    if (!accessToken) {
      logger.debug(
        'getDropboxInitializationParametersOrNull() - access token not found',
      );
      return null;
    }
    return {
      accessToken,
      ...dropboxAppAuthInitializationParameters,
    };
  }

  async function getDropboxAppAuthInitializationParameters(): Promise<DropboxOptions> {
    logger.debug('getDropboxAppAuthInitializationParameters()');
    const shouldUseStageBackend = await getShouldUseStageBackend();
    const shouldTraceRequests = await getShouldTraceRequests();

    const customHeaders: { [key: string]: string | number } = {};

    if (shouldUseStageBackend) {
      customHeaders['X-Dropbox-Backend'] = 'stage';
    }

    if (shouldTraceRequests) {
      customHeaders['X-Dropbox-Force-Request-Tracing'] = 1;
    }

    return {
      clientId: config.clientId,
      clientSecret: config.clientSecret,
      domain: config.domain,
      customHeaders,
    };
  }

  async function getAuthenticationURL(
    redirectState: RedirectState | null,
  ): Promise<string> {
    logger.debug('getAuthenticationURL()');
    let url = (await dbx.getAuthenticationUrl(
      config.oauthRedirectURL, // redirectUrl
      redirectState ? btoa(JSON.stringify(redirectState)) : sessionId, // redirectState
      'code', // authType
      'offline', // accessType
      undefined, // scopes
      undefined, //includedGrantedScope
      true, // usePKCE
    )) as string;

    const installId = await storage.get('installId');

    // XXX: these additional url parameters are for product analytics tracking.
    // "_net" is used to track the onboarding session and associate it with the
    // logged in user later.
    url += `&_net=${installId}&utm_source=product&utm_medium=dash_desktop`;

    const forceReauthentication = await storage.get('forceReauthentication');
    if (forceReauthentication) {
      url += '&force_reauthentication=true';
      // Reset the forceReauthentication
      storage.set('forceReauthentication', false);
    }

    logger.info('getAuthenticationURL() - generated url', hash(url));
    return url;
  }

  async function getLoginPathWithReturnRedirectURLParam(): Promise<string> {
    if (!config.loginRedirectConfig) {
      throw new Error('loginRedirectConfig not set for this environment');
    }
    const searchParams = new URLSearchParams(location.search);
    const redirectToPath = location.href.replace(
      new RegExp(`^${location.origin}`),
      '',
    );
    const { rootRoutePath, loginRoutePath, redirectToPathURLParam } =
      config.loginRedirectConfig;

    if (redirectToPath !== rootRoutePath) {
      searchParams.set(redirectToPathURLParam, redirectToPath);
    }
    const search = searchParams.toString();
    return `${loginRoutePath}${search ? `?${search}` : ''}`;
  }

  logoutService.registerLogoutCallback(ServiceId.DASH_AUTH, async () => {
    logger.debug('Handling logout in auth service');
    await logout();
    logger.debug('Done handling logout in auth service');
  });

  return services.provide(
    ServiceId.DASH_AUTH,
    {
      authenticate,
      refreshAccessToken,
      checkAndRefreshAccessToken,
      exchangeCodeForToken,
      getAccessToken,
      resetAuthenticationData,
      getAuthenticationData,
      getCurrentAccount,
      getDropboxInitializationParameters,
      getDropboxAppAuthInitializationParameters,
      getInstallId,
      getSessionId,
      getShouldUseStageBackend,
      getShouldTraceRequests,
      getUpdaterId,
      listen,
      listenForAccount,
      listenForAccountIds,
      logout,
      setInstallId,
      setManagedAccessToken,
      setShouldUseStageBackend,
      setShouldTraceRequests,
      isFreshLogin,
      setIsFreshLogin,
      migrateFromExternalManagement,
      getLoginPathWithReturnRedirectURLParam,
    },
    [],
  );
}

// 5 min buffer
const tokenExpirationBuffer = 5 * 60 * 1000;
export function isTokenExpired(expiration: number): boolean {
  return Date.now() + tokenExpirationBuffer > expiration;
}
