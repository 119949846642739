import { IconButton } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { UIIcon } from '@dropbox/dig-icons';
import { ChevronRightLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { ComposeAssistantConversationMessage } from '@mirage/shared/compose/compose-session';
import classnames from 'classnames';
import { memo, useEffect, useRef, useState } from 'react';
import styles from './ConversationFollowUpSuggestions.module.css';

interface ConversationFollowUpSuggestionsProps {
  message: ComposeAssistantConversationMessage;
  onClickFollowUpSuggestion: (suggestion: string) => void;
}
export const ConversationFollowUpSuggestions = memo(
  ({
    onClickFollowUpSuggestion,
    message,
  }: ConversationFollowUpSuggestionsProps) => {
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const isNearEnd =
          container.scrollWidth -
            container.scrollLeft -
            container.clientWidth <=
          30;
        setIsScrolledToEnd(isNearEnd);
      }
    };
    const scrollRight = () => {
      const container = containerRef.current;
      if (container) {
        container.scrollBy({ left: 200, behavior: 'smooth' });
      }
    };
    useEffect(() => {
      const container = containerRef.current;
      if (container) {
        container.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
          container.removeEventListener('scroll', handleScroll);
        };
      }
    }, []);
    return (
      <>
        <div
          className={classnames(styles.ConversationMessageFollowUpSuggestions, {
            [styles.ConversationMessageScrollEnd]: isScrolledToEnd,
          })}
          ref={containerRef}
        >
          {message.type === 'message' &&
            message?.followUpSuggestions?.map((suggestion, i) => (
              <Chip
                key={`follow-up-${i}`}
                className={styles.ConversationMessageFollowUpSuggestionButton}
                variant="transparent"
                onClick={() => onClickFollowUpSuggestion(suggestion)}
              >
                <Chip.Content>{suggestion}</Chip.Content>
              </Chip>
            ))}
          {isScrolledToEnd ? (
            <div className={styles.ConversationMessageSpacer} />
          ) : (
            <div className={styles.ConversationMessageFollowUpArrow}>
              <IconButton
                variant="borderless"
                shape="standard"
                size="small"
                onClick={scrollRight}
              >
                <UIIcon src={ChevronRightLine} />
              </IconButton>
            </div>
          )}
        </div>
      </>
    );
  },
);
ConversationFollowUpSuggestions.displayName = 'ConversationFollowUpSuggestions';
