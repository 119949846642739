import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { LogLevels } from 'consola';

import type { LogLevel, LogType } from 'consola';

export type LogMessage = {
  date: Date;
  message: string;
  level: LogType;
  label?: string;
  extra?: Array<unknown | object | Array<unknown>>;
};

export type Transport = (logs: LogMessage[]) => void;

export type SinkTransport = {
  log: (logs: LogMessage[]) => void;
  exportLogs?: () => void;
};

export type Service = ReturnType<typeof loggingService>;

export type LogArchiver = {
  outputDirectory: string;
  archive: (content: string) => Promise<Buffer>;
};

type Sink = {
  transport: SinkTransport;
  level: LogLevel;
};

export default function loggingService(
  sinks: Sink[],
  consoleLogLevel: LogLevel,
) {
  function transport(logs: LogMessage[]) {
    for (const sink of sinks) {
      const filtered = logs.filter((log) => {
        // consola orders by level of noise. i.e. error is lower than warn
        return LogLevels[log.level] <= sink.level;
      });
      if (!filtered.length) continue;
      sink.transport.log(filtered);
    }
  }

  transport satisfies Transport;

  function getLevel(): LogLevel {
    return consoleLogLevel;
  }

  function exportLogs(): void {
    sinks.forEach(async ({ transport }) => {
      // export logs if the transport supports it
      transport.exportLogs?.();
    });
  }

  return services.provide(
    ServiceId.LOGGING,
    {
      transport: transport as Transport,
      getLevel,
      exportLogs,
    },
    [],
  );
}
