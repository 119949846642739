import { PAP_Click_AddSource } from '@mirage/analytics/events/types/click_add_source';
import { PAP_Click_RemoveSource } from '@mirage/analytics/events/types/click_remove_source';
import { PAP_Edit_EditorTextArea } from '@mirage/analytics/events/types/edit_editor_text_area';
import { useComposeSessionsContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeSessionsContext';
import {
  SourcesContentCache,
  useComposeSourcesCache,
} from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import { useComposeVoicesContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeVoicesContext';
import {
  PostUserMessageParams,
  usePostMessageHandlers,
} from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionPostMessage';
import {
  composeCurrentSessionReducer,
  newSession,
} from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionStates';
import {
  ComposeArtifact,
  ComposeAssistantConversationMessage,
  ComposeAssistantDraftConfig,
  ComposeSession,
  ComposeSource,
  InputContext,
} from '@mirage/shared/compose/compose-session';
import { useDebounce } from '@mirage/shared/hooks/useDebounce';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useComposeAnalyticsContext } from '../ComposeAnalyticsContext';

export interface ComposeCurrentSessionContextInterface {
  // persisted
  messagesHistory: ComposeAssistantConversationMessage[];
  sources: ComposeSource[];
  artifacts: ComposeArtifact[];

  // transient
  sourcesContentCache: SourcesContentCache;
  inputContext: InputContext | undefined;
  isWaitingForResponse: boolean;
  currentSessionID: string;

  // methods
  postUserMessage: (messageParams: PostUserMessageParams) => void;
  postUpdateDraftWithVoice: (voiceID: string) => void;
  addRawMessage: (message: ComposeAssistantConversationMessage) => void;
  addSource: (source: ComposeSource) => void;
  removeSource: (source: ComposeSource) => void;
  setMarkdownContent: (markdownContent: string) => void;
  setDraftConfig: (config: ComposeAssistantDraftConfig) => void;
  setInputContext: (context: InputContext | undefined) => void;
  addArtifact: (artifact: ComposeArtifact) => void;
  newSession: (history: ComposeAssistantConversationMessage[]) => void;
  loadSession: (sessionID: string) => void;
}
export const ComposeCurrentSessionContext =
  createContext<ComposeCurrentSessionContextInterface | null>(null);
export const useComposeCurrentSessionContext = () => {
  const context = useContext(ComposeCurrentSessionContext);
  if (!context) {
    throw new Error(
      'useComposeCurrentSessionContext must be used within a ComposeCurrentSessionContextProvider',
    );
  }
  return context;
};

interface ComposeCurrentSessionContextProviderProps {
  children: React.ReactNode;
}
export const ComposeCurrentSessionContextProvider = ({
  children,
}: ComposeCurrentSessionContextProviderProps) => {
  const [hasAddedSource, setHasAddedSource] = useState(false);
  const { voices } = useComposeVoicesContext();
  const { sessions, saveSession } = useComposeSessionsContext();
  const { logComposeEvent } = useComposeAnalyticsContext(
    'compose_source_modal',
  );
  const debouncedLogKeystroke = useDebounce(() => {
    logComposeEvent(
      PAP_Edit_EditorTextArea({
        isFirstAction: !hasAddedSource,
      }),
      {
        actionSurfaceComponent: 'compose_editor_pane',
      },
    );
  }, 1000);
  const prevSessionsRef = useRef(sessions);

  const [state, dispatch] = useReducer(
    composeCurrentSessionReducer,
    undefined,
    () => {
      return {
        currentSession: newSession([]),
        inputContext: undefined,
        isWaitingForResponse: false,
      };
    },
  );
  const sourcesContents = useComposeSourcesCache(state.currentSession.sources);
  const markdownArtifact = useMemo(
    () =>
      state.currentSession.artifacts.find((a) => a.type === 'markdown_draft'),
    [state.currentSession.artifacts],
  );
  const currentVoice = useMemo(() => {
    return voices?.find((v) => v.id === markdownArtifact?.draftConfig.voiceID);
  }, [markdownArtifact?.draftConfig.voiceID, voices]);
  const voiceSourceContents = useComposeSourcesCache(
    currentVoice?.sources || [],
  );

  useEffect(() => {
    // save current session when modified, but only if there is user-entered data
    if (isEmptySession(state.currentSession)) {
      return;
    }
    saveSession(state.currentSession);
  }, [state.currentSession, saveSession]);

  useEffect(() => {
    // if current session is deleted, reset to a new session
    if (isEmptySession(state.currentSession)) {
      return; // ignore unsaved sessions
    }
    if (prevSessionsRef.current === sessions) {
      return; // ignore cases where sessions didn't change
    }
    const deletedSessions = prevSessionsRef.current?.filter(
      (s) => !sessions?.find((ss) => ss.id === s.id),
    );
    prevSessionsRef.current = sessions;

    if (deletedSessions?.find((s) => s.id === state.currentSession.id)) {
      dispatch({
        type: 'newSession',
        history: [],
      });
    }
  }, [sessions, state.currentSession]);

  const { postUserMessage, postUpdateDraftWithVoice } = usePostMessageHandlers(
    state,
    dispatch,
    voices,
    sourcesContents,
    voiceSourceContents,
    logComposeEvent,
    markdownArtifact,
  );

  const context: ComposeCurrentSessionContextInterface = {
    messagesHistory: state.currentSession.messagesHistory,
    sources: state.currentSession.sources,
    artifacts: state.currentSession.artifacts,
    sourcesContentCache: sourcesContents,
    inputContext: state.inputContext,
    isWaitingForResponse: state.isWaitingForResponse,
    currentSessionID: state.currentSession.id,

    postUserMessage,
    postUpdateDraftWithVoice,
    addRawMessage: useCallback(
      (message: ComposeAssistantConversationMessage) => {
        dispatch({
          type: 'addMessage',
          message,
          requiresResponse: false,
        });
      },
      [],
    ),
    addSource: useCallback((source: ComposeSource) => {
      dispatch({
        type: 'addSource',
        source,
      });
      logComposeEvent(PAP_Click_AddSource());
      setHasAddedSource(true);
    }, []),
    removeSource: useCallback((source: ComposeSource) => {
      dispatch({
        type: 'removeSource',
        source,
      });
      logComposeEvent(PAP_Click_RemoveSource());
    }, []),
    setMarkdownContent: useCallback((markdownContent: string) => {
      dispatch({
        type: 'setMarkdownContent',
        content: markdownContent,
      });
      debouncedLogKeystroke();
    }, []),
    setDraftConfig: useCallback((config: ComposeAssistantDraftConfig) => {
      dispatch({
        type: 'setDraftConfig',
        config,
      });
    }, []),
    setInputContext: useCallback((context: InputContext | undefined) => {
      dispatch({
        type: 'setInputContext',
        context,
      });
    }, []),
    addArtifact: useCallback((artifact: ComposeArtifact) => {
      dispatch({
        type: 'addArtifact',
        artifact,
      });
    }, []),
    newSession: useCallback(
      (history: ComposeAssistantConversationMessage[]) => {
        dispatch({
          type: 'newSession',
          history,
        });
      },
      [],
    ),
    loadSession: useCallback(
      (sessionID: string) => {
        const session = sessions?.find((s) => s.id === sessionID);
        if (!session) {
          return;
        }
        dispatch({
          type: 'loadSession',
          session,
        });
      },
      [sessions],
    ),
  };
  return (
    <ComposeCurrentSessionContext.Provider value={context}>
      {children}
    </ComposeCurrentSessionContext.Provider>
  );
};

function isEmptySession(session: Omit<ComposeSession, 'lastUpdated'>): boolean {
  return (
    session.messagesHistory.filter((m) => m.role === 'user').length === 0 &&
    session.sources.length === 0 &&
    !hasNonEmptyArtifact(session.artifacts)
  );
}

function hasNonEmptyArtifact(artifacts: ComposeArtifact[]): boolean {
  return artifacts.some(
    (a) =>
      (a.draftConfig.contentDescription || '').trim().length > 0 ||
      a.markdownContent.trim().length > 0,
  );
}
