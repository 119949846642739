import { PAP_Click_FormatButton } from '@mirage/analytics/events/types/click_format_button';
import { PAP_Click_VoiceDeleteButton } from '@mirage/analytics/events/types/click_voice_delete_button';
import { PAP_Click_VoiceMenuOption } from '@mirage/analytics/events/types/click_voice_menu_option';
import { PAP_Click_VoiceSaveButton } from '@mirage/analytics/events/types/click_voice_save_button';
import { ComposeEditor } from '@mirage/mosaics/ComposeAssistant/components/editor/ComposeEditor';
import { SelectionAction } from '@mirage/mosaics/ComposeAssistant/components/editor/SelectionToolbar';
import { VoiceSettingsModal } from '@mirage/mosaics/ComposeAssistant/components/settings/VoiceSettingsModal';
import { useComposeAnalyticsContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeAnalyticsContext';
import { useComposeVoicesContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeVoicesContext';
import { useComposeCurrentSessionContext } from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { getSelectionActionPromptString } from '@mirage/mosaics/ComposeAssistant/data/llm/llm';
import { DEFAULT_PRECONFIGURED_VOICE_ID } from '@mirage/shared/compose/compose-session';
import { getFirstMarkdownArtifact } from '@mirage/shared/compose/compose-session';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import i18n from '@mirage/translations';
import { memo, useCallback, useMemo, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

interface VoiceSettingsModalConfig {
  type: 'open';
  voiceID?: string;
}

export const ComposeEditorPane = memo(() => {
  const {
    isWaitingForResponse,
    artifacts,
    setMarkdownContent,
    setDraftConfig,
    postUpdateDraftWithVoice,
    messagesHistory,
  } = useComposeCurrentSessionContext();
  const { logComposeEvent: logEditorPaneEvent } = useComposeAnalyticsContext(
    'compose_editor_pane',
  );
  const { logComposeEvent: logVoicesPaneEvent } = useComposeAnalyticsContext(
    'compose_voices_pane',
  );
  const { voices, saveVoice, deleteVoice } = useComposeVoicesContext();
  const [openVoiceSettingsConfig, setOpenVoiceSettingsConfig] = useState<
    VoiceSettingsModalConfig | undefined
  >();
  const markdownArtifact = useMemo(
    () => getFirstMarkdownArtifact(artifacts),
    [artifacts],
  );

  const handleTriggerSelectionAction = useHandleTriggerSelectionAction();
  const handleChangeVoiceID = useCallback(
    (voiceID: string) => {
      postUpdateDraftWithVoice(voiceID);
    },
    [postUpdateDraftWithVoice],
  );
  const handleDeleteVoice = useCallback(
    (voiceId: string) => {
      handleChangeVoiceID(DEFAULT_PRECONFIGURED_VOICE_ID);
      deleteVoice(voiceId);
      logVoicesPaneEvent(PAP_Click_VoiceDeleteButton(), {
        actionSurfaceComponent: 'compose_voices_pane',
      });
      setOpenVoiceSettingsConfig(undefined);
    },
    [deleteVoice, logVoicesPaneEvent, handleChangeVoiceID],
  );
  const handleCreateNewVoice = useCallback(() => {
    const voice: ComposeVoice = {
      id: uuid4(),
      name: i18n.t('compose_new_style'),
      sources: [],
    };
    saveVoice(voice)
      .then(() => {
        setDraftConfig({
          ...markdownArtifact?.draftConfig,
          voiceID: voice.id,
        });
        setOpenVoiceSettingsConfig({ type: 'open', voiceID: voice.id });
        logVoicesPaneEvent(PAP_Click_VoiceSaveButton());
        return;
      })
      .catch((error) => {
        throw error;
      });
  }, [
    markdownArtifact?.draftConfig,
    saveVoice,
    setDraftConfig,
    logVoicesPaneEvent,
  ]);
  const handleOpenVoiceSettings = useCallback(
    (voiceID: string | undefined) => {
      logEditorPaneEvent(
        PAP_Click_VoiceMenuOption({
          actionType: 'edit',
        }),
      );
      setOpenVoiceSettingsConfig({ type: 'open', voiceID });
    },
    [logEditorPaneEvent],
  );
  const handleCloseVoicesSettings = useCallback(() => {
    setOpenVoiceSettingsConfig(undefined);
  }, []);

  return (
    <>
      <ComposeEditor
        isWaitingForResponse={isWaitingForResponse}
        markdownContent={markdownArtifact?.markdownContent || ''}
        onChangeContent={setMarkdownContent}
        onTriggerSelectionAction={handleTriggerSelectionAction}
        currentVoiceID={markdownArtifact?.draftConfig.voiceID}
        customVoices={voices}
        onChangeVoiceID={handleChangeVoiceID}
        onCreateNewVoice={handleCreateNewVoice}
        onOpenVoiceSettings={handleOpenVoiceSettings}
        logComposeEvent={logEditorPaneEvent}
        disabled={messagesHistory.length === 0}
      />
      {openVoiceSettingsConfig && (
        <VoiceSettingsModal
          voices={voices || []}
          saveVoice={saveVoice}
          onDeleteVoice={handleDeleteVoice}
          initialVoiceID={openVoiceSettingsConfig.voiceID}
          onRequestClose={handleCloseVoicesSettings}
          logComposeEvent={logVoicesPaneEvent}
        />
      )}
    </>
  );
});
ComposeEditorPane.displayName = 'ComposeEditorPane';

function useHandleTriggerSelectionAction() {
  const { setInputContext, postUserMessage } =
    useComposeCurrentSessionContext();
  const { logComposeEvent } = useComposeAnalyticsContext('compose_editor_pane');
  return useCallback(
    (action: SelectionAction) => {
      logComposeEvent(
        PAP_Click_FormatButton({
          actionType: action.type,
        }),
      );
      switch (action.type) {
        case 'make-longer':
        case 'make-shorter':
        case 'rewrite':
          postUserMessage({
            text: `${getSelectionActionPromptString(action.type)}:
------------
${action.selectedText}
------------
`,
            mustGenerateDraft: false,
            mustIncludeSourceContents: false,
            isTextSelectionMenuAction: true,
          });
          break;
        case 'prompt':
          setInputContext({
            type: 'selection',
            selectedText: action.selectedText,
          });
          break;
        default:
          action.type satisfies never;
          throw new Error(`Unknown action type: ${action}`);
      }
    },
    [postUserMessage, setInputContext, logComposeEvent],
  );
}
