import { IconButton } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { MoreHorizontalLine } from '@dropbox/dig-icons/assets';
import { DeleteConfirmationModal } from '@mirage/mosaics/ComposeAssistant/components/settings/DeleteConfirmationModal';
import {
  ComposeSession,
  getFirstMarkdownArtifact,
} from '@mirage/shared/compose/compose-session';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { differenceInDays, format, isToday, isYesterday } from 'date-fns';
import { memo, useCallback, useMemo, useState } from 'react';
import styles from './SessionsList.module.css';

interface SessionsListProps {
  sessions: ComposeSession[];
  currentSessionID: string | undefined;
  onClickSession: (session: ComposeSession) => void;
  onDeleteSession: (session: ComposeSession) => void;
}
export const SessionsList = memo(
  ({
    sessions,
    currentSessionID,
    onClickSession,
    onDeleteSession,
  }: SessionsListProps) => {
    const groupedSessions = useGroupedSessions(sessions);
    return (
      <div className={styles.SessionsListContent}>
        <div className={styles.SessionsRows}>
          {groupedSessions.map((group) => (
            <div className={styles.SessionsRowsGroup} key={group.id}>
              <Text
                variant="label"
                className={styles.SessionsRowsGroupTitle}
                isBold
                size="small"
                tagName="div"
              >
                {group.title}
              </Text>
              {group.sessions.map((session) => (
                <SessionRow
                  key={session.id}
                  session={session}
                  isSelected={currentSessionID === session.id}
                  onClickSession={onClickSession}
                  onDeleteSession={onDeleteSession}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  },
);
SessionsList.displayName = 'SessionsList';

interface SessionRowProps {
  session: ComposeSession;
  isSelected: boolean;
  onClickSession: (session: ComposeSession) => void;
  onDeleteSession: (session: ComposeSession) => void;
}
export const SessionRow = memo(
  ({
    session,
    isSelected,
    onClickSession,
    onDeleteSession,
  }: SessionRowProps) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const handleClick = useCallback(() => {
      onClickSession(session);
    }, [onClickSession, session]);
    const handleClickDelete = useCallback(() => {
      setIsDeleteModalOpen(true);
    }, []);

    const markdownArtifact = getFirstMarkdownArtifact(session.artifacts);
    const { title } = parseMarkdownContent(
      markdownArtifact?.markdownContent || '',
    );
    return (
      <>
        <div
          className={classnames(styles.SessionsRow, {
            [styles.SessionsRowSelected]: isSelected,
          })}
          onClick={handleClick}
          onKeyDown={onKeyDownCommitFn(handleClick)}
          tabIndex={0}
          role="button"
        >
          <div className={styles.SessionRowHeader}>
            <Text
              size="medium"
              tagName="div"
              className={styles.SessionRowTitle}
            >
              {title}
            </Text>
            <SessionRowActionsMenu onClickDelete={handleClickDelete} />
          </div>
        </div>
        {isDeleteModalOpen && (
          <DeleteSessionConfirmationModal
            isOpen
            onCancel={() => setIsDeleteModalOpen(false)}
            onSubmit={() => {
              onDeleteSession(session);
              setIsDeleteModalOpen(false);
            }}
          />
        )}
      </>
    );
  },
);
SessionRow.displayName = 'SessionRow';

function parseMarkdownContent(content: string) {
  const parts = content.split('\n');
  // strip any leading '#' characters
  const title =
    (parts[0] || '').replace(/^#+\s*/, '') ||
    i18n.t('compose_session_untitled_label');
  const body = parts.slice(1).join('\n').trim();
  return { title, body };
}

interface DeleteSessionConfirmationModalProps {
  onSubmit: () => void;
  onCancel: () => void;
  isOpen: boolean;
}
export const DeleteSessionConfirmationModal = memo(
  ({ onSubmit, onCancel, isOpen }: DeleteSessionConfirmationModalProps) => {
    return (
      <DeleteConfirmationModal
        title={i18n.t('compose_delete_session_confirm_title')}
        description={i18n.t('compose_delete_session_confirm_description')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        isOpen={isOpen}
      />
    );
  },
);
DeleteSessionConfirmationModal.displayName = 'DeleteSessionConfirmationModal';

export function groupSessions(sessions: ComposeSession[]) {
  // group sessions into Today, Yesterday, Previous 30 Days, per Month
  const groups: {
    today?: ComposeSession[];
    yesterday?: ComposeSession[];
    previous30Days?: ComposeSession[];
    perMonth: Map<number, ComposeSession[]>;
  } = {
    perMonth: new Map(),
  };
  const now = new Date();
  for (const session of sessions) {
    const date = new Date(session.lastUpdated);
    if (isToday(date)) {
      groups.today = groups.today || [];
      groups.today.push(session);
    } else if (isYesterday(date)) {
      groups.yesterday = groups.yesterday || [];
      groups.yesterday.push(session);
    } else if (differenceInDays(now, date) <= 30) {
      groups.previous30Days = groups.previous30Days || [];
      groups.previous30Days.push(session);
    } else {
      const month = date.getMonth();
      const group = groups.perMonth.get(month) || [];
      groups.perMonth.set(month, group);
    }
  }
  return groups;
}

interface SessionGroup {
  id: string;
  title: string;
  sessions: ComposeSession[];
}
function useGroupedSessions(sessions: ComposeSession[]): SessionGroup[] {
  return useMemo(() => {
    const groups = groupSessions(sessions);
    const groupedSessions: SessionGroup[] = [];
    if (groups.today) {
      groupedSessions.push({
        id: 'today',
        title: i18n.t('today'),
        sessions: groups.today,
      });
    }
    if (groups.yesterday) {
      groupedSessions.push({
        id: 'yesterday',
        title: i18n.t('yesterday'),
        sessions: groups.yesterday,
      });
    }
    if (groups.previous30Days) {
      groupedSessions.push({
        id: 'previous30Days',
        title: i18n.t('previous_30_days'),
        sessions: groups.previous30Days,
      });
    }
    for (const [month, monthSessions] of groups.perMonth) {
      const date = new Date();
      date.setMonth(month);
      groupedSessions.push({
        id: `month-${month}`,
        title: format(date, 'MMMM'),
        sessions: monthSessions,
      });
    }
    return groupedSessions;
  }, [sessions]);
}

interface SessionRowActionsMenuProps {
  onClickDelete: () => void;
}
export const SessionRowActionsMenu = memo(
  ({ onClickDelete }: SessionRowActionsMenuProps) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    return (
      <Menu.Wrapper
        onToggle={({ isOpen }) => setIsMenuOpened(isOpen)}
        onClick={(e) => {
          // prevent clicks in menu from affecting containing elements
          e.stopPropagation();
        }}
      >
        {({ getContentProps, getTriggerProps }) => {
          return (
            <>
              <div
                className={classnames(styles.SessionRowHeaderActions, {
                  [styles.SessionRowHeaderActionsOpened]: isMenuOpened,
                })}
              >
                <IconButton
                  {...getTriggerProps()}
                  variant="borderless"
                  size="small"
                >
                  <UIIcon src={MoreHorizontalLine} />
                </IconButton>
              </div>
              <Menu.Content {...getContentProps()}>
                <Menu.Segment>
                  <Menu.ActionItem
                    key="delete"
                    value="Delete"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onClickDelete();
                    }}
                  >
                    {i18n.t('delete')}
                  </Menu.ActionItem>
                </Menu.Segment>
              </Menu.Content>
            </>
          );
        }}
      </Menu.Wrapper>
    );
  },
);
SessionRowActionsMenu.displayName = 'SessionRowActionsMenu';
