import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { ConsolaInstance } from 'consola';
import * as rx from 'rxjs';

const TIMEOUT_MS = 5000;

export interface Service {
  logout(): Promise<void>;
  registerService(service: ServiceId): rx.Observable<boolean>;
  completeService(service: ServiceId): void;
}

export type ServiceAdapter = {
  logout(): Promise<void>;
};

interface LoggingServiceContract {
  tagged: (tag: string) => ConsolaInstance;
}

let registered: ServiceId[] = [];

export default function logoutService(
  loggingService: LoggingServiceContract,
  adapter: ServiceAdapter,
) {
  const logger = loggingService.tagged('service-logout');
  const inProgress = new rx.Subject<boolean>();
  const allCompleted = new rx.Subject<boolean>();

  async function logout() {
    logger.debug(
      'Logout called, waiting for services to complete logout activity...',
    );
    inProgress.next(true);

    const timeout$ = rx.timer(TIMEOUT_MS).pipe(rx.map(() => 'timeout'));
    const allCompleted$ = allCompleted.pipe(rx.map(() => 'completed'));
    const allCompletedWithTimeout$ = rx.race(allCompleted$, timeout$);
    const result = await rx.lastValueFrom(allCompletedWithTimeout$);

    if (result === 'timeout') {
      logger.warn(
        `Timeout occurred during logout, shutting down app anyway. We were still waiting on logout complete signals from these services: ${registered.join(
          ', ',
        )}`,
      );
    }

    logger.debug(
      'All services completed logout activity, proceeding with adapter logout',
    );
    return adapter.logout();
  }

  // WARN: If you `registerService` you must reliably `completeService` or else
  // you'll block logout!
  function registerService(serviceId: ServiceId) {
    logger.debug(`Registering ${serviceId} for logout activity`);
    registered.push(serviceId);
    return inProgress;
  }

  function completeService(serviceId: ServiceId) {
    registered = registered.filter((id) => id !== serviceId);

    if (registered.length === 0) {
      logger.debug(
        `Completed ${serviceId}. All registered services have completed logout activity now.`,
      );
      allCompleted.next(true);
      allCompleted.complete();
    } else {
      logger.debug(
        `Completed ${serviceId} waiting for: ${registered.join(', ')}`,
      );
    }
  }

  const service = {
    logout,
    registerService,
    completeService,
  };

  return services.provide<Service>(ServiceId.LOGOUT, service, []);
}
