import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import { TypeaheadResultType } from '@mirage/analytics/events/enums/typeahead_result_type';
import { PAP_Change_DashSearchQuery } from '@mirage/analytics/events/types/change_dash_search_query';
import { SEARCH_ATTEMPT_END_REASONS } from '@mirage/analytics/session/session-utils';
import { useIsDropboxer } from '@mirage/service-auth/useDropboxAccount';
import { useActivationForConnectors } from '@mirage/service-connectors/useActivationForConnectors';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool as convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { tagged } from '@mirage/service-logging';
import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import { registerHit, search } from '@mirage/service-typeahead-search';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import useDashSearchResultLogger from '@mirage/shared/hooks/useDashSearchResultLogger';
import useSearchQueryId from '@mirage/shared/hooks/useSearchQueryId';
import { useStableCallback } from '@mirage/shared/util/hooks';
import { atom, useAtom } from 'jotai';
import { throttle } from 'lodash';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useListenForTypeaheadReset } from './useListenForTypeaheadReset';

import type { stacks } from '@dropbox/api-v2-client';

const logger = tagged('service-typeahead-search/useTypeaheadSearch');

export const TypeaheadQueryAtom = atom<string>('');

export function useTypeaheadSearch(useInstancedQuery?: boolean) {
  const { connections } = useConnectors();

  const [typeaheadQuery, _setTypeaheadQueryState] =
    useTypeaheadQueryState(useInstancedQuery);
  /**
   * the typeahead query is duplicated as a ref to ensure we can always grab
   * the latest value regardless of what the value was when the performSearch
   * function was invoked
   **/
  const typeaheadQueryRef = useRef('');
  const setTypeaheadQueryState = useCallback(
    (innerTypeaheadQuery: string) => {
      typeaheadQueryRef.current = innerTypeaheadQuery;
      _setTypeaheadQueryState(innerTypeaheadQuery);
    },
    [_setTypeaheadQueryState],
  );

  // Determine if typeahead is eligible to be shown
  // Actual visibility will be determined by other factors like length of results
  const [canShowTypeahead, setCanShowTypeahead] = useState(false);

  const [results, setResults] = useState<typeahead.ScoredResult[]>([]);
  const { setOnboardingValue } = useOnboardingValues();
  const { searchAttemptSessionManager, searchSessionManager, reportPapEvent } =
    useMirageAnalyticsContext();
  const { createSearchQueryId, clearSearchQueryId } = useSearchQueryId();
  const {
    // Open
    logPreviousQueryOpen,
    logStackResultOpen,
    logStackItemResultOpen,
    logRecommendationOpen,
    logURLShortcutOpen,
    logDocumentOpen,
    logMathCalculationOpen,

    // Copy
    logTypeaheadResultCopy,

    // Shown
    logStackResultShown,
    logStackItemResultShown,
    logPreviousQueryShown,
    logMathCalculationShown,
    logDesktopFileShown,
    logDesktopApplicationShown,
    logDocumentShown,
    logRecommendationShown,
    logURLShortcutShown,

    // Add to stack
    logTypeaheadAddToStack,
  } = useDashSearchResultLogger();

  const { activationType } = useActivationForConnectors();

  const resetTypeaheadState = useCallback(() => {
    setTypeaheadQueryState('');
    setCanShowTypeahead(false);
  }, [setTypeaheadQueryState]);
  useListenForTypeaheadReset(resetTypeaheadState);

  //
  // build typeahead search config/context
  // ---------------------------------------------------------------------------
  const isDropboxer = useIsDropboxer() || false;
  const isFilterOutEvents = convertFeatureValueToBool(
    useFeatureFlagValue(`dash_2024_05_15_typeahead_filter_out_events`),
  );
  const isStackItemResultsEnabled = convertFeatureValueToBool(
    useFeatureFlagValue(`dash_2024_06_27_local_typeahead_stack_items`),
  );
  const isNullStateUsesRecommendations = convertFeatureValueToBool(
    useFeatureFlagValue(
      'dash_2024_07_29_typeahead_null_state_uses_recommendations',
    ),
  );
  const isTypeaheadFiltersEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_09_30_typeahead_filters'),
  );
  //
  // typeahead search functionality
  // ---------------------------------------------------------------------------
  //
  const onResultsReturned = useCallback(
    (query: string, results: typeahead.ScoredResult[]) => {
      if (!query) {
        clearSearchQueryId();
        return;
      }

      const searchQueryUuid = createSearchQueryId();

      const resultUuidList = results
        ?.filter((r) => {
          if (r.type === typeahead.ResultType.PreviousQuery) {
            return false;
          }
          return true;
        })
        .map((r) => r.uuid)
        .join(',');

      reportPapEvent(
        PAP_Change_DashSearchQuery({
          resultUuidList,
          featureLine: 'search',
          actionSurfaceComponent: 'search_bar',
          searchQueryUuid,
          queryString: query,
          searchAttemptId:
            searchAttemptSessionManager.getSessionIdOrUndefined(),
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        }),
      );
    },
    [
      clearSearchQueryId,
      createSearchQueryId,
      reportPapEvent,
      searchAttemptSessionManager,
      searchSessionManager,
    ],
  );

  const performSearch = useCallback(
    (
      internalActiveQuery: string,
      isDropboxer: boolean,
      onComplete?: (lastEmittedResults: typeahead.ScoredResult[]) => void,
    ) => {
      if (!canShowTypeahead) {
        // this clears out previous results when the typeahead closes, so the
        // next time it opens it'll do a fresh typeahead search instead of
        // showing the old results.
        setResults([]);
        return;
      }

      // Keep order consistent with section above
      const searchConfig: typeahead.Config = {
        isDropboxer,
        isFilterOutEvents,
        isStackItemResultsEnabled,
        isTypeaheadFiltersEnabled,
        isNullStateUsesRecommendations,
        connections,
      };

      const results$ = search(internalActiveQuery, searchConfig);
      let lastEmittedResults: typeahead.ScoredResult[] = [];
      const subscription = results$.subscribe({
        next: (resultsWithoutUpdatedQuery) => {
          const results = resultsWithoutUpdatedQuery.map(
            (result: typeahead.ScoredResult) =>
              result.type === typeahead.ResultType.SuggestedQuery
                ? {
                    ...result,
                    result: {
                      ...result.result,
                      // the current query is pulled from a ref to ensure that
                      // we are always grabbing the latest query, not the query
                      // at the time performSearch was invoked
                      query: typeaheadQueryRef.current,
                    },
                  }
                : result,
          );
          logger.debug(`emitted results`, results);

          onResultsReturned(internalActiveQuery, results);
          lastEmittedResults = results;
          setResults(results);
        },
        error: (e) => {
          logger.error(`typeahead search error`, e);
          setResults([]);
        },
        complete: () => {
          onComplete?.(lastEmittedResults);
          logger.debug(`typeahead search complete`);
        },
      });
      return () => subscription.unsubscribe();
    },
    [
      isFilterOutEvents,
      isNullStateUsesRecommendations,
      isStackItemResultsEnabled,
      canShowTypeahead,
      onResultsReturned,
      connections,
    ],
  );
  // using throttle over debounce ensures that the initial search is triggered
  // sooner, the final results will come sooner, and the user will constantly
  // see feedback as they type no matter how fast they're typing
  // and throttling on the search ensures that the update of the SERP CTA is nearly instant
  const performSearchThrottled = useMemo(
    () => throttle(performSearch, 250, { trailing: true, leading: true }),
    [performSearch],
  );

  useEffect(() => {
    return performSearchThrottled(typeaheadQuery, isDropboxer);
  }, [typeaheadQuery, isDropboxer, performSearchThrottled]);

  // every time the query changes, immediately update the SERP CTA in the
  // results array with the new query text as fast as possible
  useLayoutEffect(() => {
    setResults((previousResults) =>
      previousResults.map((result: typeahead.ScoredResult) =>
        result.type === typeahead.ResultType.SuggestedQuery
          ? { ...result, result: { ...result.result, query: typeaheadQuery } }
          : result,
      ),
    );
  }, [typeaheadQuery]);

  const reloadResults = useCallback(() => {
    performSearch(typeaheadQuery, isDropboxer);
  }, [typeaheadQuery, isDropboxer, performSearch]);

  /**
   * Used to perform a typeahead search after the user has hit enter but before
   * we actually pick the results. This allows us to ensure that we have all
   * results for all sources before we pick the item to launch.
   */
  const performPreLaunchSearch = useCallback(
    (
      finalQuery: string,
      onComplete: (lastEmittedResults: typeahead.ScoredResult[]) => void,
    ) => {
      performSearch(finalQuery, isDropboxer, onComplete);
    },
    [isDropboxer, performSearch],
  );

  const setTypeaheadQuery = useCallback(
    async (query: string, taggedQuery?: boolean) => {
      setTypeaheadQueryState(query);
      const hasQuery = query.length > 0;

      if (!hasQuery) {
        searchAttemptSessionManager.endSession(
          SEARCH_ATTEMPT_END_REASONS.QUERY_MANUALLY_CLEARED,
        );
      } else {
        searchAttemptSessionManager.extendOrCreateSession('type_query');
        searchAttemptSessionManager.updateProperties({
          query,
          isTypeahead: taggedQuery,
        });

        // If a query exists, set has_query to true
        // If a query does not exist, leave it as true for the remainder of the session
        searchSessionManager.updateProperties({
          hasQuery: true,
        });
      }
    },
    [setTypeaheadQueryState, searchAttemptSessionManager, searchSessionManager],
  );

  //
  // external handlers and logging
  // --------------------------------------------------------------------------

  // delegate to appropriate logging when result is opened
  const papLogResultOpen = useStableCallback(
    (result: typeahead.ScoredResult, launchMethod: LaunchMethod) => {
      // Dismiss the query suggestion banner if the user successfully selects a result
      setOnboardingValue('dismissedQuerySuggestionBanner', true);
      switch (result.type) {
        case typeahead.ResultType.Stack:
          logStackResultOpen(
            {
              // The core Stack type (`result.result`) doesn't have uuid, only
              // namespace_id. Pass through the locally-generated uuid for
              // reporting purposes.
              uuid: result.uuid,
              stack: result.result,
              query: typeaheadQuery,
              filters: [],
              results,
            },
            false,
            true,
            launchMethod,
            'search_dropdown',
            'stack',
          );
          registerHit(result.uuid);
          break;
        case typeahead.ResultType.StackItem:
          logStackItemResultOpen(
            {
              stackItem: result.result,
              query: typeaheadQuery,
              filters: [],
              results,
            },
            true,
            false,
            launchMethod,
            'search_dropdown',
            'stack_item',
          );
          registerHit(result.uuid);
          break;
        case typeahead.ResultType.URLShortcut:
          logURLShortcutOpen(
            {
              urlShortcut: result.result,
              query: typeaheadQuery,
              filters: [],
              results,
            },
            false,
            true,
            launchMethod,
            'search_dropdown',
            'url_shortcut',
          );
          break;
        case typeahead.ResultType.SearchResult:
          logDocumentOpen(
            {
              searchResult: result.result,
              query: typeaheadQuery,
              filters: [],
              results,
            },
            false,
            true,
            launchMethod,
            'search_dropdown',
            'content',
            'connector',
          );
          registerHit(result.uuid);
          break;
        case typeahead.ResultType.Recommendation:
          logRecommendationOpen(
            {
              recommendation: result.result,
              query: typeaheadQuery,
              filters: [],
              results,
            },
            false,
            true,
            launchMethod,
            'search_dropdown',
            'recommendation',
            'connector',
          );
          registerHit(result.uuid);
          break;
        case typeahead.ResultType.PreviousQuery:
          logPreviousQueryOpen(
            {
              previousQuery: result.result,
              query: typeaheadQuery,
              filters: [],
              results,
            },
            false,
            launchMethod,
            'search_dropdown',
            'query_suggestion',
          );
          break;
        case typeahead.ResultType.SuggestedQuery:
          logPreviousQueryOpen(
            {
              previousQuery: result.result,
              query: typeaheadQuery,
              filters: [],
              results,
            },
            false,
            launchMethod,
            'search_dropdown',
            'serp_cta',
          );
          break;
        case typeahead.ResultType.DesktopFile:
          logDocumentOpen(
            {
              searchResult: result.result,
              query: typeaheadQuery,
              filters: [],
              results,
            },
            false,
            true,
            launchMethod,
            'search_dropdown',
            'local_file',
            'local_file_and_applications',
          );
          registerHit(result.uuid);
          break;
        case typeahead.ResultType.DesktopApplication:
          logDocumentOpen(
            {
              searchResult: result.result,
              query: typeaheadQuery,
              filters: [],
              results,
            },
            false,
            true,
            launchMethod,
            'search_dropdown',
            'local_app',
            'local_file_and_applications',
          );
          registerHit(result.uuid);
          break;
        case typeahead.ResultType.MathCalculation:
          logMathCalculationOpen(
            {
              mathCalculation: result.result,
              query: typeaheadQuery,
              filters: [],
              results,
            },
            false,
            true,
            launchMethod,
            'search_dropdown',
            'math_calculation',
          );
          break;
        case typeahead.ResultType.SearchFilter:
          // TODO: log search filter results
          break;
        default:
          // Typescript should give an error on the next line if you're missing any possible cases
          result satisfies never;
          logger.error(
            `[papLogResultOpen] Unrecognized result type, not reigstering open event`,
          );
      }
    },
  );

  const papLogResultCopy = useStableCallback(
    (result: typeahead.ScoredResult) => {
      let typeaheadResultType: TypeaheadResultType | undefined = undefined;
      // Assign the proper result type. Not all typeahead results can be copied to clipboard.
      switch (result.type) {
        case typeahead.ResultType.Stack:
          typeaheadResultType = 'stack';
          break;
        case typeahead.ResultType.StackItem:
          typeaheadResultType = 'stack_item';
          break;
        case typeahead.ResultType.URLShortcut:
          typeaheadResultType = 'url_shortcut';
          break;
        case typeahead.ResultType.SearchResult:
          typeaheadResultType = 'content';
          break;
        case typeahead.ResultType.Recommendation:
          typeaheadResultType = 'recommendation';
          break;
        case typeahead.ResultType.MathCalculation:
          typeaheadResultType = 'math_calculation';
          break;
        case typeahead.ResultType.SearchFilter:
          // TODO: log search filter results
          // typeaheadResultType = 'search_filter';
          break;
        default:
          // Typescript should give an error on the next line if you're missing any possible cases
          result satisfies
            | typeahead.ScoredPreviousQuery
            | typeahead.ScoredSuggestedQuery
            | typeahead.ScoredDesktopFile
            | typeahead.ScoredDesktopApplication;
      }
      if (typeaheadResultType) {
        logTypeaheadResultCopy(
          {
            result,
            query: typeaheadQuery,
            filters: [],
            results,
          },
          'search_dropdown',
          typeaheadResultType,
        );
      }
    },
  );

  // delegate to appropriate logging fn when result is viewed
  const papLogResultShown = useStableCallback(
    (result: typeahead.ScoredResult) => {
      switch (result.type) {
        case typeahead.ResultType.Stack:
          handleShownStackResult(result);
          break;
        case typeahead.ResultType.StackItem:
          handleShownStackItemResult(result);
          break;
        case typeahead.ResultType.URLShortcut:
          handleShownURLShortcutResult(result);
          break;
        case typeahead.ResultType.SearchResult:
          handleShownSearchResult(result);
          break;
        case typeahead.ResultType.Recommendation:
          handleShownRecommendationResult(result);
          break;
        case typeahead.ResultType.PreviousQuery:
          handleShownPreviousQueryResult(result);
          break;
        case typeahead.ResultType.SuggestedQuery:
          handleShownSuggestedQueryResult(result);
          break;
        case typeahead.ResultType.MathCalculation:
          handleShownMathCalculationResult(result);
          break;
        case typeahead.ResultType.SearchFilter:
          // TODO: Log search filter result
          break;
        case typeahead.ResultType.DesktopFile:
          handleShownDesktopFileResult(result);
          break;
        case typeahead.ResultType.DesktopApplication:
          handleShownDesktopApplicationResult(result);
          break;
        default:
          // Typescript should give an error on the next line if you're missing any possible cases
          result satisfies never;
          logger.error(
            `[papLogResultShown] Unrecognized result type, not registering shown event`,
          );
      }
    },
  );

  //
  // internal helpers
  // --------------------------------------------------------------------------

  const extendSearchSession = () => {
    searchSessionManager.extendOrCreateSession('scroll_typeahead');
    if (typeaheadQuery !== '') {
      // If a query exists, set has_query to true
      // If a query does not exist, leave it as true for the remainder of the session
      searchSessionManager.updateProperties({
        hasQuery: true,
      });
    }
  };

  // on viewing a result of type 'stack'
  const handleShownStackResult = (result: typeahead.ScoredStack) => {
    logStackResultShown(
      {
        uuid: result.uuid,
        stack: result.result,
        query: typeaheadQuery,
        filters: [],
        results,
      },
      'search_dropdown',
      'stack',
    );
    extendSearchSession();
  };

  // on viewing a result of type 'stack-item'
  const handleShownStackItemResult = (result: typeahead.ScoredStackItem) => {
    logStackItemResultShown(
      {
        stackItem: result.result,
        query: typeaheadQuery,
        filters: [],
        results,
      },
      'search_dropdown',
      'stack_item',
    );
    extendSearchSession();
  };

  // on viewing a result of type 'search-result'
  const handleShownSearchResult = (result: typeahead.ScoredSearchResult) => {
    logDocumentShown(
      {
        searchResult: result.result,
        query: typeaheadQuery,
        filters: [],
        results,
      },
      true,
      'search_dropdown',
      'content',
    );
    extendSearchSession();
  };

  // on viewing a result of type 'recommendation'
  const handleShownRecommendationResult = (
    result: typeahead.ScoredRecommendation,
  ) => {
    logRecommendationShown(
      {
        recommendation: result.result,
        query: typeaheadQuery,
        filters: [],
        results,
      },
      true,
      'search_dropdown',
      'recommendation',
    );
    extendSearchSession();
  };
  // on viewing a result of type 'previous-query'
  const handleShownPreviousQueryResult = (
    result: typeahead.ScoredPreviousQuery,
  ) => {
    logPreviousQueryShown(
      {
        previousQuery: result.result,
        query: typeaheadQuery,
        filters: [],
        results,
      },
      'search_dropdown',
      'query_suggestion',
    );
    extendSearchSession();
  };

  // on viewing a result of type 'suggested-query' / cta of "search dash..."
  const handleShownSuggestedQueryResult = (
    result: typeahead.ScoredSuggestedQuery,
  ) => {
    logPreviousQueryShown(
      {
        previousQuery: result.result,
        query: typeaheadQuery,
        filters: [],
        results,
      },
      'search_dropdown',
      'serp_cta',
    );
    extendSearchSession();
  };

  const handleShownMathCalculationResult = (
    result: typeahead.ScoredMathCalculation,
  ) => {
    logMathCalculationShown(
      {
        mathCalculation: result.result,
        query: typeaheadQuery,
        filters: [],
        results,
      },
      'search_dropdown',
    );
    extendSearchSession();
  };

  const handleShownDesktopFileResult = (
    result: typeahead.ScoredDesktopFile,
  ) => {
    logDesktopFileShown(
      {
        desktopFile: result.result,
        query: typeaheadQuery,
        filters: [],
        results,
      },
      'search_dropdown',
    );
    extendSearchSession();
  };

  const handleShownDesktopApplicationResult = (
    result: typeahead.ScoredDesktopApplication,
  ) => {
    logDesktopApplicationShown(
      {
        desktopApplication: result.result,
        query: typeaheadQuery,
        filters: [],
        results,
      },
      'search_dropdown',
    );
    extendSearchSession();
  };

  const handleShownURLShortcutResult = (
    result: typeahead.ScoredURLShortcut,
  ) => {
    logURLShortcutShown(
      {
        urlShortcut: result.result,
        query: typeaheadQuery,
        filters: [],
        results,
      },
      'search_dropdown',
    );
    extendSearchSession();
  };

  const papLogAddToStack = (
    result: typeahead.ScoredResult,
    stack: stacks.Stack | undefined,
  ) => {
    logTypeaheadAddToStack(
      {
        result,
        query: typeaheadQuery,
        filters: [],
        results,
      },
      stack,
      'search_dropdown',
    );
  };

  return {
    typeaheadQuery,
    canShowTypeahead,
    setTypeaheadQuery,
    setCanShowTypeahead,
    papLogResultOpen,
    papLogResultCopy,
    papLogResultShown,
    papLogAddToStack,
    reloadResults,
    typeaheadResults: results,
    activationType,
    performPreLaunchSearch,
  };
}

function useTypeaheadQueryState(
  useInstancedQuery?: boolean,
): [string, React.Dispatch<React.SetStateAction<string>>] {
  const [typeaheadQueryAtom, setTypeaheadQueryAtom] =
    useAtom(TypeaheadQueryAtom);
  const [typeaheadQueryInstanced, setTypeaheadQueryInstanced] =
    useState<string>('');
  return useInstancedQuery
    ? [typeaheadQueryInstanced, setTypeaheadQueryInstanced]
    : [typeaheadQueryAtom, setTypeaheadQueryAtom];
}
