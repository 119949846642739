import { Chip } from '@dropbox/dig-components/chip';
import { UIIcon } from '@dropbox/dig-icons';
import { AppsLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { SearchFilterIcon } from '@mirage/shared/icons/SearchFilterIcon';
import { SearchFilterType } from '@mirage/shared/search/search-filters';
import React from 'react';
import styles from './SearchHeaderFilterChip.module.css';

import type { SearchFilter } from '@mirage/shared/search/search-filters';

type Props = {
  filter: SearchFilter;
};

const SearchFilterChipIcon = ({ filter }: Props) => {
  switch (filter.type) {
    case SearchFilterType.Connector:
      if (filter.parameters.iconLightSrc) {
        return (
          <Chip.IconAccessory>
            <SearchFilterIcon filter={filter} size={16} />
          </Chip.IconAccessory>
        );
      }
      return (
        <Chip.IconAccessory>
          <UIIcon src={AppsLine} />
        </Chip.IconAccessory>
      );
    case SearchFilterType.Person:
    case SearchFilterType.LastUpdated:
    case SearchFilterType.ContentType:
      break;
    default:
      filter satisfies never;
  }

  return null;
};

export const SearchHeaderFilterChip = ({ filter }: Props) => {
  let filterText;
  switch (filter.type) {
    case SearchFilterType.Connector:
      filterText = `In:${filter.parameters.displayName}`;
      break;
    case SearchFilterType.Person:
    case SearchFilterType.LastUpdated:
    case SearchFilterType.ContentType:
      break;
    default:
      filter satisfies never;
  }

  return (
    <Chip className={styles.FilterChip}>
      <SearchFilterChipIcon filter={filter} />
      <Chip.Content>{filterText}</Chip.Content>
    </Chip>
  );
};
