import { SearchFilterIcon } from '@mirage/shared/icons/SearchFilterIcon';
import {
  type SearchFilter,
  SearchFilterType,
} from '@mirage/shared/search/search-filters';
import i18n from '@mirage/translations';
import React from 'react';

export function getTypeaheadFilterDisplayProps(filter: SearchFilter): {
  title: JSX.Element | string;
  subtitle?: JSX.Element | string;
  icon: JSX.Element;
} {
  switch (filter.type) {
    case SearchFilterType.Connector:
      return {
        title: filter.parameters.displayName,
        subtitle: i18n.t('search_filter_result_byline'),
        icon: <SearchFilterIcon filter={filter} />,
      };
    case SearchFilterType.Person:
    case SearchFilterType.ContentType:
    case SearchFilterType.LastUpdated:
    default:
      return {
        title: '',
        icon: <></>,
      };
  }
}
