import { ml_capabilities } from '@dropbox/api-v2-client/types/dropbox_types';
import { ContentCacheLoaded } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import {
  LLMFunctionDefinition,
  LLMResponse,
} from '@mirage/mosaics/ComposeAssistant/data/llm/llm-apis';
import { AssistantResponse } from '@mirage/mosaics/ComposeAssistant/data/llm/llm-types';
import { tagged } from '@mirage/service-logging';

const logger = tagged('ComposeAssistant/llm-tools');

export function handleToolCall(
  response: LLMResponse,
  toolCall: ml_capabilities.ToolCall,
  indexedCachedSources: Map<number, ContentCacheLoaded>,
  onResponse: (response: AssistantResponse) => void,
): ml_capabilities.ChatMessage[] {
  if (toolCall.function === undefined) {
    throw new Error(
      `Missing function for Chat completion response: ${toolCall.function}`,
    );
  }
  if (toolCall.function.arguments === undefined) {
    throw new Error(
      `Missing arguments for Chat completion response: ${toolCall.function.arguments}`,
    );
  }
  const args = JSON.parse(toolCall.function.arguments);
  switch (toolCall.function.name) {
    case 'write_doc':
      {
        try {
          const content = args.content;
          const followUpSuggestions = args.follow_up_suggestions; // this is optional
          onResponse({
            type: 'write_doc',
            content,
            followUpSuggestions,
            responseText: response.responseText,
          });
        } catch (e) {
          throw new Error(
            `Failed to parse Chat completion response: ${toolCall.function.arguments}`,
          );
        }
        return [];
      }
      break;
    case 'read_source':
      {
        const sourceIndex = Number(args.sourceIndex);
        const cachedSource = indexedCachedSources.get(sourceIndex);
        if (cachedSource === undefined) {
          throw new Error(`Invalid source index: ${sourceIndex}`);
        }
        const { title, url } = getCachedSourceInfo(cachedSource);
        const toolCallId = response.responseMessage.tool_calls
          ? response.responseMessage.tool_calls[0].id
          : undefined;
        const toolResponseMessage: ml_capabilities.ChatMessage = {
          '.tag': 'tool_message',
          tool_call_id: toolCallId,
          content: {
            text: `Response to read_source call:
Title: ${title}:
URL: ${url}
Content:
${truncateContent(cachedSource.content)}`,
          },
        };
        onResponse({
          type: 'read_source',
          source: cachedSource,
        });
        return [response.responseMessage, toolResponseMessage];
      }
      break;
    default:
      logger.error('unexpected tool call', toolCall.function.name);
      throw new Error(`Unexpected tool call: ${toolCall.function.name}`);
  }
}

export function getTools(
  mustIncludeSourceContents: boolean,
): LLMFunctionDefinition[] {
  const tools: LLMFunctionDefinition[] = [
    {
      name: 'write_doc',
      description: 'Write a draft document',
      parameters: {
        type: 'object',
        properties: {
          content: {
            type: 'string',
            description:
              'The full content of the draft document, in Markdown format',
          },
          follow_up_suggestions: {
            type: 'array',
            items: {
              type: 'string',
              description: `Up to 3 suggestions for follow-up actions, e.g. "Rewrite this more casually".
These may be about specifying the tone/style of the document, or including additional details on a specific topic.`,
            },
          },
        },
        required: ['content', 'follow_up_suggestions'],
      },
    },
  ];
  if (!mustIncludeSourceContents) {
    // if content already included directly, no need to offer this as a function
    tools.push({
      name: 'read_source',
      description: 'Read a source document',
      parameters: {
        type: 'object',
        properties: {
          sourceIndex: {
            type: 'number',
            description: 'index of the source to read',
          },
        },
        required: ['sourceIndex'],
      },
    });
  }
  return tools;
}

// TODO: deal with cases where prompt content is too long
const MAX_CONTENT_SIZE = 32000;

export function getCachedSourceInfo(cachedSource: ContentCacheLoaded) {
  const title =
    cachedSource.source.type === 'dash_search_result'
      ? cachedSource.source.searchResult.title
      : cachedSource.source.recommendation.title;
  const url =
    cachedSource.source.type === 'dash_search_result'
      ? cachedSource.source.searchResult.url
      : cachedSource.source.recommendation.url;
  return { title, url };
}

export function truncateContent(content: string) {
  return content.substring(0, MAX_CONTENT_SIZE);
}
