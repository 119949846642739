import {
  ContentIcon,
  Metadata,
  SimpleTruncate,
} from '@dropbox/dash-component-library';
import { Avatar } from '@dropbox/dig-components/avatar';
import { BaseButtonProps, Button } from '@dropbox/dig-components/buttons';
import { ClickOutside } from '@dropbox/dig-components/click_outside';
import { List } from '@dropbox/dig-components/list';
import { Overlay, OverlayPlacement } from '@dropbox/dig-components/overlay';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { Split, Stack } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_CalendarAttachment } from '@mirage/analytics/events/types/click_calendar_attachment';
import { PAP_Click_JoinMeeting } from '@mirage/analytics/events/types/click_join_meeting';
import { PAP_Click_OpenCalendarItem } from '@mirage/analytics/events/types/click_open_calendar_item';
import { PAP_Collapse_CalendarItemDescription } from '@mirage/analytics/events/types/collapse_calendar_item_description';
import { PAP_Expand_CalendarItemDescription } from '@mirage/analytics/events/types/expand_calendar_item_description';
import { PAP_Shown_CalendarItem } from '@mirage/analytics/events/types/shown_calendar_item';
import { DashFacepile } from '@mirage/dash-component-library/components/DashFacepile';
import { Link } from '@mirage/link/Link';
import { FavIcon } from '@mirage/link-list/Favicon/Favicon';
import { openURL } from '@mirage/service-platform-actions';
import { useIsHoverFriendly } from '@mirage/shared/responsive/hover';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import { getInitialsFromName } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { isBefore } from 'date-fns';
import dompurify from 'dompurify';
import { AnimatePresence, motion } from 'framer-motion';
import {
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './CalendarEventComponent.module.css';
import {
  EVENT_TIME_DELTA_REFRESH_INTERVAL,
  MAX_FACES_IN_FACEPILE,
  MOTION_SETTINGS,
  ONDECK_EVENT_THRESHOLD,
  UPCOMING_EVENT_THRESHOLD,
} from './constants';
import {
  CalendarEvent,
  getClickedAnchor,
  getNamesFromAttendees,
  getProximateTimeString,
  getShowableAttendees,
  getTimespanString,
} from './utils';

export const CalendarEventComponent = ({
  event,
  expanded = false,
  isEarliestEvent,
}: {
  event: CalendarEvent;
  expanded?: boolean;
  isEarliestEvent: boolean;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const hasUrl = !!(event.calendarUrl || event.meetingUrl);
  const proximate =
    !event.allDay &&
    isBefore(event.startTime, Date.now() + UPCOMING_EVENT_THRESHOLD);
  const ondeck =
    !event.allDay &&
    isBefore(event.startTime, Date.now() + ONDECK_EVENT_THRESHOLD);

  // button should always be shown
  const showButtonAlways = hasUrl && proximate;
  // show this only if a button isn't already shown. only one button will ever be rendered
  const showButtonWhenExpanded = hasUrl && !showButtonAlways && expanded;
  const primaryButton = proximate || (ondeck && isEarliestEvent);

  const eventButton = (
    <CalendarEventButton
      {...{ event }}
      variant={primaryButton ? 'primary' : 'outline'}
    />
  );

  useEffect(() => {
    reportPapEvent(
      PAP_Shown_CalendarItem({
        meetingId: event.uuid,
        featureLine: 'calendar',
        actionSurfaceComponent: 'calendar',
      }),
    );
  }, [reportPapEvent, event.uuid]);

  return (
    <div className={classNames([styles.event, { [styles.bright]: proximate }])}>
      <Stack>
        <CalendarEventHeader {...{ event, expanded, proximate, ondeck }} />
        <AnimatePresence initial={false}>
          {expanded && (
            <motion.div
              layout
              layoutId="event"
              {...MOTION_SETTINGS}
              className={styles.expandableContent}
            >
              <CalendarEventBody event={event} />
              {showButtonWhenExpanded && eventButton}
            </motion.div>
          )}
        </AnimatePresence>
        {showButtonAlways && eventButton}
      </Stack>
    </div>
  );
};

const CalendarEventHeader = ({
  event,
  expanded,
  proximate,
  ondeck,
}: {
  event: CalendarEvent;
  expanded: boolean;
  proximate: boolean;
  ondeck: boolean;
}) => {
  const timespanString = useMemo(
    () => getTimespanString(event.startTime, event.endTime, event.allDay),
    [event.startTime, event.endTime, event.allDay],
  );
  return (
    <Stack gap="Micro Small">
      <div>
        <Metadata>
          <Metadata.Item>
            <Metadata.Label>
              <Text variant="label" color="subtle">
                {timespanString}
              </Text>
            </Metadata.Label>
          </Metadata.Item>
          {!event.allDay && (proximate || ondeck) && (
            <CalendarEventHeaderProximateTime {...{ event, proximate }} />
          )}
        </Metadata>
      </div>
      <div>
        <Text isBold>
          {expanded ? (
            event.title
          ) : (
            <DashTruncateWithTooltip
              lines={2}
              tooltipProps={{ title: event.title }}
            >
              {event.title}
            </DashTruncateWithTooltip>
          )}
        </Text>
      </div>
    </Stack>
  );
};

export type CalendarEventHeaderProximateTimeProps = {
  event: CalendarEvent;
  proximate: boolean;
};

export const CalendarEventHeaderProximateTime = ({
  event,
  proximate,
}: CalendarEventHeaderProximateTimeProps) => {
  const [timeString, setTimeString] = useState<string | undefined>(
    getProximateTimeString(event.startTime, event.endTime),
  );

  useEffect(() => {
    const intervalId = setInterval(
      () =>
        setTimeString(getProximateTimeString(event.startTime, event.endTime)),
      EVENT_TIME_DELTA_REFRESH_INTERVAL,
    );
    return () => {
      clearInterval(intervalId);
    };
  }, [event, setTimeString]);

  return (
    <Metadata.Item withDividers="bullet">
      <Metadata.Label>
        <Text
          variant="label"
          color="subtle"
          isBold={proximate}
          className={classNames({ [styles.proximate]: proximate })}
        >
          {timeString}
        </Text>
      </Metadata.Label>
    </Metadata.Item>
  );
};

const CalendarEventBody = ({ event }: { event: CalendarEvent }) => {
  const shownAttendees = getShowableAttendees(event.attendees);
  const numUnshownAttendees = event.attendeesCount - shownAttendees.length;
  const showStack = event.attachments.length > 0 || !!event.description.content;
  return (
    <>
      {shownAttendees.length > 0 && (
        <CalendarEventAttendees {...{ shownAttendees, numUnshownAttendees }} />
      )}
      {showStack && (
        <Stack gap="Micro Small" className={styles.bodyStack}>
          {event.attachments.length > 0 && (
            <CalendarEventAttachments
              event={event}
              attachments={event.attachments.filter(
                (attachment) => attachment.url && attachment.title,
              )}
            />
          )}
          {!!event.description && <CalendarEventDescription event={event} />}
        </Stack>
      )}
    </>
  );
};

const CalendarEventAttendees = ({
  shownAttendees,
  numUnshownAttendees,
}: {
  shownAttendees: CalendarEvent['attendees'];
  numUnshownAttendees: number;
}) => {
  const facepileMembers = shownAttendees.slice(0, MAX_FACES_IN_FACEPILE);
  const [showTooltip, setShowTooltip] = useState(false);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const mouseLeaveTimeout = useRef<number | null>(null);
  const totalAttendees = shownAttendees.length + numUnshownAttendees;
  const isHoverFriendly = useIsHoverFriendly();

  const handleMouseEnter = () => {
    if (mouseLeaveTimeout.current) {
      window.clearTimeout(mouseLeaveTimeout.current);
    }
    mouseLeaveTimeout.current = window.setTimeout(() => {
      setShowTooltip(true);
    }, 1000);
  };

  const handleMouseLeave = () => {
    if (mouseLeaveTimeout.current) {
      window.clearTimeout(mouseLeaveTimeout.current);
    }
    mouseLeaveTimeout.current = window.setTimeout(() => {
      setShowTooltip(false);
    }, 200);
  };

  // This is purely to handle touch events on mobile devices
  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!isHoverFriendly) {
      handleMouseEnter();
    }
  };

  return (
    <div className={styles.attendeesContainer} ref={triggerRef}>
      <Split direction="horizontal" gap="Micro Small" alignY="center">
        <Split.Item>
          <div className={styles.facepileContainer}>
            <DashFacepile
              size="xsmall"
              members={facepileMembers}
              isInteractive={false}
            />
          </div>
        </Split.Item>
        <Split.Item>
          <Text
            variant="label"
            color="subtle"
            onPointerEnter={handleMouseEnter}
            onPointerLeave={handleMouseLeave}
            onClick={handleClick}
          >
            {getNamesFromAttendees(shownAttendees, totalAttendees)}
          </Text>
          <CalendarEventAttendeesTooltip
            triggerRef={triggerRef}
            show={showTooltip}
            attendees={shownAttendees}
            attendeeCount={totalAttendees}
            onPointerEnter={handleMouseEnter}
            onPointerLeave={handleMouseLeave}
          />
        </Split.Item>
      </Split>
    </div>
  );
};

const CalendarEventAttendeesTooltip: React.FC<{
  triggerRef: RefObject<HTMLDivElement>;
  show: boolean;
  attendees: CalendarEvent['attendees'];
  attendeeCount: number;
  onPointerEnter: () => void;
  onPointerLeave: () => void;
}> = ({
  triggerRef,
  show,
  attendees,
  attendeeCount,
  onPointerEnter,
  onPointerLeave,
}) => {
  const [placement, setPlacement] = useState<OverlayPlacement>('bottom-start');
  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  if (!show) {
    return null;
  }

  return (
    <ClickOutside onClickOutside={onPointerLeave}>
      <Overlay
        className={styles.attendeesOverlay}
        placement={placement}
        auto
        offsetDistance={4}
        anchorRef={triggerRef}
        onChangePlacement={setPlacement}
        setWidthSameAsAnchor
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        onClick={handleClick}
      >
        <Text color="subtle" className={styles.overlayTitle}>
          {i18n.t('num_guests', { count: attendeeCount })}
        </Text>
        <div className={styles.overlayListContainer}>
          <List padding="0" isSelectable={false} spacing="small">
            {attendees.map((attendee) => (
              <List.Item
                key={attendee.email}
                className={styles.overlayListItem}
              >
                <List.Accessory>
                  <Avatar src={attendee.profile_image_url}>
                    {getInitialsFromName(
                      attendee.display_name || attendee.email!,
                    )}
                  </Avatar>
                </List.Accessory>
                <List.Content className={styles.overlayListItemContent}>
                  <Stack gap="var(--dig-spacing__dimension__2)">
                    <Text variant="label" tagName="div">
                      <Truncate>
                        {attendee.display_name || attendee.email}
                      </Truncate>
                    </Text>
                    <Text
                      variant="label"
                      color="subtle"
                      size="small"
                      tagName="div"
                    >
                      <Truncate>{attendee.email}</Truncate>
                    </Text>
                  </Stack>
                </List.Content>
              </List.Item>
            ))}
            {attendeeCount > attendees.length && (
              <List.Item key="more" className={styles.overlayListItem}>
                <List.Accessory>
                  <Avatar>{`+${attendeeCount - attendees.length}`}</Avatar>
                </List.Accessory>
                <List.Content>
                  <Text variant="label">
                    {i18n.t('num_others', {
                      count: attendeeCount - attendees.length,
                    })}
                  </Text>
                </List.Content>
              </List.Item>
            )}
          </List>
        </div>
      </Overlay>
    </ClickOutside>
  );
};

const CalendarEventAttachments = ({
  event,
  attachments,
}: {
  event: CalendarEvent;
  attachments: CalendarEvent['attachments'];
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  return (
    <Stack>
      {attachments.map((attachment) => (
        <Split
          key={attachment.url}
          direction="horizontal"
          gap="Micro Small"
          alignY="center"
        >
          <Split.Item>
            <ContentIcon
              icon={
                <FavIcon
                  src={
                    attachment.url ? faviconSrcForSrcUrl(attachment.url) : ''
                  }
                  pureImg
                ></FavIcon>
              }
              size="small"
            />
          </Split.Item>
          <Split.Item className={styles.linkContainer}>
            <Link
              href={attachment.url || ''} // All attachements will have a URL at this point
              target="_blank"
              variant="monochromatic"
              hasNoUnderline
              onClick={(e: SyntheticEvent) => {
                reportPapEvent(
                  PAP_Click_CalendarAttachment({
                    meetingId: event.uuid,
                    featureLine: 'calendar',
                    actionSurfaceComponent: 'calendar',
                  }),
                );

                openURL(attachment.url);

                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Truncate tooltipControlProps={{ auto: true, placement: 'top' }}>
                {attachment.title}
              </Truncate>
            </Link>
          </Split.Item>
        </Split>
      ))}
    </Stack>
  );
};

const CalendarEventDescription = ({ event }: { event: CalendarEvent }) => {
  // undefined means we don't know if the description is overflowing yet.
  // false means the collapsed content is not overflowing.
  const [isOverflowing, setIsOverflowing] = useState<boolean | undefined>();
  const [expanded, setExpanded] = useState(false);
  const sanitizedContainerRef = useRef<HTMLDivElement>(null);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const collapsible = isOverflowing || expanded;

  const handleClicked = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      // Don't toggle collapse if the user is selecting text
      if (window.getSelection()?.toString()) {
        return;
      }

      // Don't toggle collapse if the user clicked on an anchor link in the
      // expanded description.
      const target: Node = e.target as Node;
      if (sanitizedContainerRef.current?.contains(target)) {
        const anchor = getClickedAnchor(target, sanitizedContainerRef.current);
        if (anchor) {
          openURL(anchor.href);
          e.preventDefault();
          return;
        }
      }

      if (collapsible) {
        setExpanded((prev) => {
          const papEvent = prev
            ? PAP_Collapse_CalendarItemDescription
            : PAP_Expand_CalendarItemDescription;

          reportPapEvent(
            papEvent({
              actionSurfaceComponent: 'calendar',
              featureLine: 'calendar',
              meetingId: event.uuid,
            }),
          );

          return !prev;
        });
      }
    },
    [collapsible, reportPapEvent, event.uuid],
  );

  const [collapsedContent, expandedContent] = getDescriptionForms(
    event.description,
    sanitizedContainerRef,
  );

  if (!collapsedContent) {
    return null;
  }

  return (
    <div
      className={classNames(styles.eventDescription, {
        [styles.expanded]: expanded,
      })}
    >
      <Text
        color="subtle"
        className={classNames(styles.eventDescriptionText, {
          [styles.collapsible]: collapsible,
        })}
        onClick={handleClicked}
      >
        {expanded || isOverflowing === false ? (
          expandedContent
        ) : (
          <SimpleTruncate
            lines={2}
            onOverflowChange={({ isOverflowing }) =>
              setIsOverflowing(isOverflowing)
            }
          >
            {collapsedContent}
          </SimpleTruncate>
        )}
      </Text>
      {collapsible && (
        <Button
          onClick={handleClicked}
          variant="transparent"
          size="medium"
          hasNoUnderline
          className={styles.eventExpandButton}
        >
          <Text color="subtle">
            {expanded ? i18n.t('see_less') : i18n.t('see_more')}
          </Text>
        </Button>
      )}
    </div>
  );
};

function getDescriptionForms(
  description: CalendarEvent['description'],
  sanitizedContainerRef: RefObject<HTMLDivElement>,
): [string | null, string | JSX.Element | null] {
  // Return both the collapsed and expanded forms of the description content
  // If the content is plaintext, the values will both be the passed-in
  // description content.
  // If it is HTML the collapsed content will be the sanitized content string
  // stripped of HTML tags and the expanded content will be an HTML element.
  // containing the sanitized HTML content.
  // Any other contentType will return null for both.
  if (description.contentType === 'text/plain') {
    return [description.content, description.content];
  } else if (description.contentType !== 'text/html') {
    return [null, null];
  }

  const sanitized = dompurify.sanitize(description.content);
  if (!sanitized) {
    return [null, null];
  }

  // The calendar event contains HTML formatting, so we render it with dangerouslySetInnerHTML.
  // This content is sanitized on the server, but we sanitize it again here to be safe.
  return [
    getCollapsedContent(sanitized),
    <div
      key="sanitized"
      ref={sanitizedContainerRef}
      dangerouslySetInnerHTML={{ __html: sanitized }}
    />,
  ];
}

function getCollapsedContent(sanitizedHTML: string): string {
  const sanitizedDiv: HTMLDivElement = document.createElement<'div'>('div');
  sanitizedDiv.innerHTML = sanitizedHTML;

  // Recursively extracts all the text from the HTML description.
  // Differs from innerText because it inserts spaces between elements.
  return extractTextFromNode(sanitizedDiv)
    .map((s) => s.trim())
    .join(' ');
}

function extractTextFromNode(node: Node): string[] {
  const components = [];
  if (node.hasChildNodes()) {
    node.childNodes.forEach((n) => {
      components.push(...extractTextFromNode(n));
    });
  } else if (node.textContent) {
    components.push(node.textContent);
  }
  return components;
}

const CalendarEventButton = ({
  event,
  ...props
}: {
  event: CalendarEvent;
} & BaseButtonProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const url = event.meetingUrl || event.calendarUrl;
  const papEvent =
    (event.meetingUrl && PAP_Click_JoinMeeting) || PAP_Click_OpenCalendarItem;
  const onClick = useCallback(
    (e: SyntheticEvent) => {
      reportPapEvent(
        papEvent({
          featureLine: 'calendar',
          actionSurfaceComponent: 'calendar',
          meetingId: event.uuid,
        }),
      );

      openURL(url);

      e.stopPropagation();
      e.preventDefault();
    },
    [reportPapEvent, event.uuid, url, papEvent],
  );
  return (
    <div className={styles.buttonContainer}>
      <Button href={url} onClick={onClick} target="_blank" {...props}>
        {event.meetingUrl ? i18n.t('join_meeting') : i18n.t('open_calendar')}
      </Button>
    </div>
  );
};
