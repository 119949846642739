import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { listenForAccountIds } from '@mirage/service-auth';
import { isSynchronous } from '@mirage/service-platform-actions';
import {
  // We know what we are doing with CONNECTORS_URL here.
  // eslint-disable-next-line no-restricted-syntax
  CONNECTORS_URL as appsUrl,
  DASH_CONNECTORS_URL as dashAppsURL,
} from '@mirage/shared/urls';

import type { Service as LogoutService } from '@mirage/service-logout/service';

export type ExitType = 'quit' | 'quit_and_reset' | 'logout';

export interface Service {
  getConnectorsUrl(): string;
  getDashConnectorsUrl(): string;
  manualCheckForUpdates(): void;
  exit(exitType: ExitType): Promise<void>;
}

export type ServiceAdapter = {
  manualCheckForUpdates(): Promise<void>;
  exit(exitType: ExitType): void;
};

// Need to support opening urls on mobile browsers.
// eslint-disable-next-line import/no-mutable-exports
export let syncService: Service | undefined;

export default function settingActions(
  adapter: ServiceAdapter,
  logoutService: LogoutService,
) {
  let accountId = '';

  // Important: Calling getCurrentAccount() will cause openConnectors to break
  // on mobile.
  listenForAccountIds().subscribe((ids) => {
    accountId = ids?.account_id ?? '';
  });

  function getConnectorsUrl() {
    return `${appsUrl}?dbid=${accountId ?? ''}`;
  }

  function getDashConnectorsUrl() {
    return `${dashAppsURL}?dbid=${accountId ?? ''}`;
  }

  async function manualCheckForUpdates() {
    return adapter.manualCheckForUpdates();
  }

  async function exit(value: ExitType) {
    if (value === 'quit_and_reset') {
      await logoutService.logout();
    }
    return adapter.exit(value);
  }

  const service = {
    getConnectorsUrl,
    getDashConnectorsUrl,
    manualCheckForUpdates,
    exit,
  };

  if (isSynchronous()) {
    syncService = service;
  }

  services.provide<Service>(ServiceId.SETTING_ACTIONS, service, [
    ServiceId.DASH_AUTH,
  ]);
}
