/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
// XXX: for some reason, regardless of the contents of "lib" in tsconfig, when
// hornet imports this from mirage it fails to make tsc happy. this is the
// easiest solution in the interim
/// <reference lib="es2021.weakref" />
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { batchedIntervalFunc } from '@mirage/shared/util/batching';
// import redact from '@mirage/service-logging/redact';
import { createConsola, LogLevels } from 'consola';
import { BrowserReporter } from './browser-reporter';
import { normalize, serializable } from './helpers';

// import { ConsolaReporter} from 'consola'/
import type { LogMessage, Service } from '@mirage/service-logging/service';
import type { LogObject } from 'consola';

export type { Service };
// each context will have its own instance of consola's logger and allow for
// sub-context creation via tagging. these outputs will be fed into a custom
// reporter which sends information to the service to aggregate and transport
// log messages to the appropriate sinks
const service = services.getp<Service>(ServiceId.LOGGING);
const instance = createConsola({ level: LogLevels.debug });
const loggers: Map<
  string,
  WeakRef<ReturnType<typeof createConsola>>
> = new Map();

const batchedSink = batchedIntervalFunc(
  (batch: LogMessage[]) => service.transport(batch),
  // Batch to logging per second. This shouldn't be set too high as it might
  // cause us to lose some logs, but shouldn't be set too low as most of the
  // logs we have are not needed urgently.
  1000,
);

// create a transport specific to our service-based cross-proc ingestion
const log = (log: LogObject) => {
  if (!serializable(log)) {
    console.warn('attempting to log non-transferrable data, dropping!');
    return;
  }

  batchedSink(normalize(log));
};
instance.addReporter({ log });

// we have to manage setting our reporters and child reporters when the log
// level changes, track local state here as well as our service ingest reporter
const SERVICE_REPORTER = { log };
let currentLogLevel = LogLevels.info;
service
  .getLevel()
  .then((level) => {
    // update our local state on what log level we are targeting
    currentLogLevel = level;
    for (const [tag, ref] of loggers.entries()) {
      // this child may have went out of scope and got gc'd, do cleanup here
      const child = ref.deref();
      if (!child) {
        loggers.delete(tag);
        continue;
      }
      child.setReporters([
        SERVICE_REPORTER,
        // this ensures that the console mirrors our log level without being
        // impacted by the consola logger level setting (i.e. filtering out
        // logs that need to go to other sinks)
        new BrowserReporter(currentLogLevel),
      ]);
    }
  })
  .catch((e) =>
    console.warn('failed to get current log level, using "debug"', e),
  );

// of course, nothing is as easy as it seems when it comes to software. we want
// to be able to programatically set the levels of _all_ loggers simultaneously,
// which, due to how consola creates tagged instances, we need to track them
// all locally when they are created as they are not referencing their parent
export const tagged = (tag: string) => {
  const child = instance.withTag(tag);
  child.level = instance.level;
  loggers.set(tag, new WeakRef(child));
  return child;
};

export const exportLogs = () => {
  service.exportLogs();
};
