import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine } from '@dropbox/dig-icons/dist/mjs/assets';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { publishedContentsAtom } from '@mirage/stacks/hooks';
import i18n from '@mirage/translations';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListPublishedContent } from './ListPublishedContent';
import { PublishContentModal } from './PublishContentModal';
import styles from './PublishedContentPage.module.css';

const MAX_ALLOWED_PUBLISHED_CONTENTS = 20;

export const PublishedContentPage = () => {
  const enablePublishedContent = convertFeatureValueToBool(
    useFeatureFlagValue('dash_web_2024_09_24_published_content_is_admin'),
  );
  const [showPublishModal, setShowPublishModal] = useState(false);
  const account = useDropboxAccount();
  const isTeamAdmin = account?.team?.team_user_permissions?.is_admin;

  const publishedContents = useAtomValue(publishedContentsAtom);

  const [disablePublishButton, setDisablePublishButton] = useState(false);

  useEffect(() => {
    setDisablePublishButton(
      publishedContents.length > MAX_ALLOWED_PUBLISHED_CONTENTS,
    );
  }, [publishedContents]);

  const navigate = useNavigate();
  const shouldShowPublishedContent = enablePublishedContent || isTeamAdmin;

  useEffect(() => {
    if (!shouldShowPublishedContent) {
      navigate(RoutePath.NOT_FOUND);
    }
  }, [shouldShowPublishedContent]);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.titleContainer}>
          <Title weightVariant="emphasized" size="medium" tagName="h1">
            {i18n.t('published_content_title')}
          </Title>
          {disablePublishButton ? (
            <DigTooltip
              title={i18n.t('publish_button_disable_text', {
                count: MAX_ALLOWED_PUBLISHED_CONTENTS,
              })}
              placement="top"
            >
              <Box
                as="span"
                display="inline-block"
                borderRadius="Medium"
                outlineWidth="0"
                tabIndex={0}
              >
                <Button
                  variant="primary"
                  withIconStart={<UIIcon src={AddLine} />}
                  disabled
                  style={{
                    pointerEvents: 'none',
                  }}
                >
                  {i18n.t('publish_button_text')}
                </Button>
              </Box>
            </DigTooltip>
          ) : (
            <Button
              variant="primary"
              onClick={() => setShowPublishModal(true)}
              withIconStart={<UIIcon src={AddLine} />}
            >
              {i18n.t('publish_button_text')}
            </Button>
          )}
        </div>
        <Text>{i18n.t('published_content_subtitle')}</Text>
        <ListPublishedContent />
      </div>
      {showPublishModal && (
        <PublishContentModal
          open={showPublishModal}
          onClose={() => setShowPublishModal(false)}
        />
      )}
    </div>
  );
};
