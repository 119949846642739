import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { WordmarkLogo } from '@dropbox/dig-logos';
import { Link } from '@mirage/link/Link';
import { DashWordmarkText } from '@mirage/mosaics/GlobalNav/DashWordmarkText';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { DROPBOX_LOGOUT_URL, SUPPORT_URL } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import React from 'react';
import styles from './RedirectModal.module.css';

export interface RedirectModalProps {
  asset?: React.ReactNode;
  cta?: string;
  description?: string;
  primaryCta?: {
    onClick?: () => void;
    label?: string;
  };
  title?: string;
}

export const RedirectModal = ({
  asset,
  description,
  primaryCta,
  title,
}: RedirectModalProps) => {
  const account = useDropboxAccount();

  return (
    <ThemeProvider mode="bright">
      <ThemeContainer>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.header}>
              <WordmarkLogo size={32} src={DashWordmarkText} />
            </div>
            <div className={styles.assetContainer}>{asset}</div>
            <Title className={styles.noMargin} weightVariant="emphasized">
              {title}
            </Title>
            <Text color="subtle" size="large">
              {description}
            </Text>
            {primaryCta && (
              <div>
                <Button variant="primary" onClick={primaryCta?.onClick}>
                  {primaryCta?.label}
                </Button>
              </div>
            )}
            <hr className={styles.line} />
            <div>
              <Text color="subtle">{i18n.t('logged_in_as')}</Text>
              <Text isBold> {account?.email}</Text>
            </div>
            <Text color="subtle" className={styles.footer}>
              <Link href={DROPBOX_LOGOUT_URL} variant="primary">
                {i18n.t('switch_accounts')}
              </Link>
              &#x2022;
              <Link href={SUPPORT_URL} variant="primary">
                {i18n.t('contact_support')}
              </Link>
            </Text>
          </div>
        </div>
      </ThemeContainer>
    </ThemeProvider>
  );
};
