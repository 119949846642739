import { Text, Title } from '@dropbox/dig-components/typography';
import { BetaBadge } from '@mirage/shared/badges/BetaBadge';
import i18n from '@mirage/translations';
import { memo } from 'react';
import { useComposeCurrentSessionContext } from '../../data/current-session/ComposeCurrentSessionContext';
import styles from './SidePanelContainer.module.css';

export interface SidePanelContainerProps {
  leftTitleActions?: React.ReactNode;
  children: React.ReactNode;
}
export const SidePanelContainer = memo(
  ({ leftTitleActions, children }: SidePanelContainerProps) => {
    const { sources } = useComposeCurrentSessionContext();
    return (
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          {leftTitleActions && (
            <div className={styles.titleActions}>{leftTitleActions}</div>
          )}
          <div className={styles.titleAndChip}>
            <Title size="small" className={styles.title}>
              {i18n.t('compose_assistant_title')}
            </Title>
            <div className={styles.titleChip}>
              <BetaBadge />
            </div>
            {sources.length > 0 && (
              <>
                <div className={styles.sourceCountSpacer} />
                <Text size="medium" className={styles.sourceCount}>
                  {i18n.t('compose_session_number_of_sources', {
                    count: sources.length,
                    plural: sources.length > 1 ? 's' : '',
                  })}
                </Text>
              </>
            )}
          </div>
        </div>
        {children}
      </div>
    );
  },
);
SidePanelContainer.displayName = 'SidePanelContainer';
