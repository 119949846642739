import { IconButton } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddCircleLine, SendLine } from '@dropbox/dig-icons/assets';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Send_ComposePrompt } from '@mirage/analytics/events/types/send_compose_prompt';
import {
  ComposeAssistantConversationMessage,
  ComposeAssistantConversationMessageMessage,
  ComposeSource,
  InputContext,
} from '@mirage/shared/compose/compose-session';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from './ConversationInput.module.css';

interface ConversationInputProps {
  sources: ComposeSource[];
  inputContext: InputContext | undefined;
  setInputContext: (inputContext: InputContext | undefined) => void;
  onSubmit: (text: string) => void;
  onFocus?: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
  handleClickAdd: () => void;
  messages: ComposeAssistantConversationMessage[];
}
export const ConversationInput = memo(
  ({
    sources,
    inputContext,
    setInputContext,
    onSubmit,
    onFocus,
    logComposeEvent,
    handleClickAdd,
    messages,
  }: ConversationInputProps) => {
    const disabled = messages.length === 0;
    const textInput = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
      const lastMessage = messages[messages.length - 1];
      const shouldAutofocusTextArea =
        sources.length > 0 &&
        textInput.current &&
        lastMessage &&
        lastMessage.type === 'message' &&
        (
          lastMessage as ComposeAssistantConversationMessageMessage
        ).text.includes(i18n.t('compose_assistant_message_partial_match'));
      if (shouldAutofocusTextArea) {
        textInput.current.focus();
      }
    }, [sources, messages]);
    const [promptText, setPromptText] = useState('');
    const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> =
      useCallback(
        (event) => {
          if (event.key === KeyCodes.enter && promptText.trim().length > 0) {
            if (!event.shiftKey) {
              // shift-enter should create newline as normal
              event.preventDefault();
              onSubmit(promptText);
              setPromptText('');
              logComposeEvent(
                PAP_Send_ComposePrompt({
                  queryString: promptText,
                }),
              );
            }
          }
          if (event.key === 'Backspace' || event.key === 'Delete') {
            if (promptText.length === 0) {
              event.preventDefault();
              setInputContext(undefined);
            }
          }
        },
        [onSubmit, promptText, setInputContext],
      );
    const handleTextAreaChange: React.FormEventHandler<HTMLTextAreaElement> =
      useCallback((event) => {
        event.preventDefault();
        setPromptText(event.currentTarget.value);
      }, []);
    const handleSubmit: React.MouseEventHandler = useCallback(
      (event) => {
        event.preventDefault();
        if (promptText.length > 0) {
          onSubmit(promptText);
          setPromptText('');
        }
      },
      [onSubmit, promptText],
    );
    let placeholderText = i18n.t('compose_prompt_dash_placeholder');
    if (sources.length) {
      placeholderText = i18n.t(
        'compose_prompt_dash_placeholder_chat_with_sources',
        {
          count: sources.length,
          plural: sources.length > 1 ? 's' : '',
        },
      );
    } else if (messages.length !== 0) {
      placeholderText = i18n.t('compose_prompt_dash_placeholder_chat');
    }
    return (
      <div className={styles.ConversationInput}>
        <div className={styles.ConversationInputContainer}>
          <div className={styles.ConversationInputs}>
            {inputContext && (
              <Text className={styles.ConversationInputContext}>
                <Truncate lines={2}>{inputContext.selectedText}</Truncate>
              </Text>
            )}
            <TextArea
              ref={textInput}
              className={classnames(styles.ConversationInputTextArea, {
                [styles.ConversationInputTextAreaDisabled]: disabled,
              })}
              placeholder={placeholderText}
              rows={2}
              resizable="auto"
              isTransparent
              value={promptText}
              onChange={handleTextAreaChange}
              onKeyDown={handleKeyDown}
              onFocus={onFocus}
              disabled={disabled}
            />
            <div className={styles.ConversationInputSourcesActions}>
              <Chip
                className={classnames(styles.ConversationInputAddSourceChip, {
                  [styles.ConversationInputSourceChipDisabled]: disabled,
                })}
                variant="transparent"
                size="medium"
                onClick={handleClickAdd}
                disabled={disabled}
              >
                <Chip.IconAccessory>
                  <UIIcon src={AddCircleLine} />
                </Chip.IconAccessory>
                <Chip.Content>
                  {i18n.t('compose_add_source_action')}
                </Chip.Content>
              </Chip>
            </div>
          </div>
          <IconButton
            className={styles.ConversationInputSendButton}
            variant="opacity"
            shape="standard"
            size="medium"
            aria-label={i18n.t('send')}
            onClick={handleSubmit}
            disabled={promptText.trim().length === 0}
          >
            <UIIcon src={SendLine} size="standard" />
          </IconButton>
        </div>
      </div>
    );
  },
);
ConversationInput.displayName = 'ConversationInput';
