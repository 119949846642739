import {
  dash_connectors,
  Dropbox,
  DropboxOptions,
  DropboxResponseError,
} from '@dropbox/api-v2-client';
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { tagged } from '@mirage/service-logging';
import { namespace } from '@mirage/service-operational-metrics';
import { MultiAnswerResponse } from '@mirage/shared/answers/multi-answer';
import { Recommendation } from '@mirage/shared/search/recommendation';
import Sentry from '@mirage/shared/sentry';
import { Backoff } from '@mirage/shared/util/backoff';
import { runWithRetries } from '@mirage/shared/util/retries';
import {
  AnswerResponse,
  ConversationResponse,
  getAnswersForQuery,
  getConversation,
  getImplicitAnswer,
} from './answers';
import {
  getDocAnswer,
  getDocSummary,
} from './grpc/context_engine_apiv2/doc_summarization';
import {
  getAnswerForQuestion,
  getLinkSummary,
} from './grpc/link_summarization_qna/fetch_summary_qna';
import { getSearchResults } from './search';
import { getTimeoutMsForApi } from './timeouts';
import { getTypeaheadRecommendations } from './typeahead';
import { upstreamForConnection } from './upstream';

import type { SearchResult } from './search';
import type { APIResponse, APIRoute } from './types';
import type { dash, DropboxResponse } from '@dropbox/api-v2-client';
import type { LogoutServiceConsumerContract } from '@mirage/service-logout';
import type {
  CONNECTOR_TYPES,
  SearchFilter,
} from '@mirage/shared/search/search-filters';

const LOGOUT_ERROR_MAP: Record<number, Set<string>> = {
  401: new Set(['invalid_access_token', 'user_suspended']),
};

export type Connection = dash_connectors.Connection & {
  connector?: Connector;
};

export type Connector = dash_connectors.Connector & {
  type?: CONNECTOR_TYPES;
};

export type APIv2AuthType = 'app' | 'user';
export { SearchResult };

export type APIv2Callable = <Route extends APIRoute>(
  apiName: Route,
  args: Parameters<Dropbox[Route]>[0],
  authType?: APIv2AuthType,
) => Promise<APIResponse<Route>>;

export type APIv2CallableWithHeaders = <Route extends APIRoute>(
  apiName: Route,
  args: Parameters<Dropbox[Route]>[0],
  authType?: APIv2AuthType,
) => Promise<DropboxResponse<APIResponse<Route>>>;

export type Service = ReturnType<typeof dashApi>;

interface AuthServiceContract {
  getDropboxAppAuthInitializationParameters: () => Promise<DropboxOptions>;
  getDropboxInitializationParameters: () => Promise<DropboxOptions>;
}

const metrics = namespace('dbx-api');
const logger = tagged('dbx-api');

function fetchWithTimeout(timeout: number): typeof fetch {
  return function (input: Parameters<typeof fetch>[0], init) {
    const signal = AbortSignal.timeout(timeout);
    const overloaded = init ? { ...init, signal } : { signal };
    return fetch(input, overloaded);
  } as typeof fetch;
}

export default function dashApi(
  authService: AuthServiceContract,
  logoutService: LogoutServiceConsumerContract,
  dontReportLatenciesForApiNames?: Set<APIRoute>,
) {
  async function callApiV2<Route extends APIRoute>(
    apiName: Route,
    args: Parameters<Dropbox[Route]>[0],
    authType: APIv2AuthType = 'user',
  ): Promise<APIResponse<Route>> {
    return callApiV2WithResponseHeaders(apiName, args, authType).then(
      ({ result }) => result,
    );
  }

  async function callApiV2WithResponseHeaders<Route extends APIRoute>(
    apiName: Route,
    args: Parameters<Dropbox[Route]>[0],
    authType: APIv2AuthType = 'user',
  ): Promise<DropboxResponse<APIResponse<Route>>> {
    // Time out problematic calls from client side.
    const timeoutMs = getTimeoutMsForApi(apiName);
    const dbxOptions =
      authType === 'user'
        ? await authService.getDropboxInitializationParameters()
        : await authService.getDropboxAppAuthInitializationParameters();
    const client = new Dropbox({
      ...dbxOptions,
      fetch: fetchWithTimeout(timeoutMs),
    });
    return callApiV2WithClient(apiName, args, client);
  }

  async function callApiV2WithClient<Route extends APIRoute>(
    apiName: Route,
    args: Parameters<Dropbox[Route]>[0],
    client: Dropbox,
  ): Promise<DropboxResponse<APIResponse<Route>>> {
    const method = client[apiName] as (
      args: Parameters<Dropbox[Route]>[0],
    ) => Promise<DropboxResponse<APIResponse<Route>>>;

    // We cannot repro the TimeoutError easily in dev, but we know that the
    // string form is 'TimeoutError: signal timed out'.
    function isTimeoutError(e: unknown) {
      return `${e}`.startsWith('TimeoutError');
    }

    async function run(attemptNum: number, numAttempts: number) {
      const t0 = performance.now();
      let status: 'success' | 'error' | 'timeout' = 'success';

      try {
        return await method.apply(client, [args]);
      } catch (e) {
        status = 'error';

        if (e instanceof DropboxResponseError && e?.error) {
          const errTag = e.error?.error?.['.tag'];
          const errCode = e.status;

          // Skip reporting 401
          if (errCode !== 401) {
            logger.warn(
              `Error calling Dropbox API - ${apiName}, error Code - ${errCode}, error - ${JSON.stringify(
                e?.error,
              )}`,
            );
          }

          if (LOGOUT_ERROR_MAP[errCode]?.has(errTag)) {
            // Cannot re-check eligibility due to invalid_access_token
            Sentry.captureMessage(
              `Logging out user from calling API, error - ${JSON.stringify(
                e?.error,
              )}`,
            );
            await logoutService.logout();
          }
        } else if (isTimeoutError(e)) {
          status = 'timeout';
          logger.warn(
            `Timeout calling Dropbox API: attempt ${attemptNum} of ${numAttempts} - ${apiName}`,
            e,
          );
        } else {
          logger.warn(`Error calling Dropbox API - ${apiName}`, e);
        }

        throw e;
      } finally {
        // Prevent infinite loop with reporting latency for the API that sends
        // the latencies to the server.
        if (!dontReportLatenciesForApiNames?.has(apiName)) {
          metrics.counter(`status/${apiName}`, 1, {
            status,
          });
          metrics.stats(`latency/${apiName}`, performance.now() - t0, {
            status,
          });
        }
      }
    }

    return await runWithRetries(run, {
      numAttempts: 5,
      backoff: new Backoff(500, 5_000),
      logError: (
        e: unknown,
        attemptNum: number,
        numAttempts: number,
        backoffMs: number,
      ) => {
        if (isTimeoutError(e)) {
          if (attemptNum < numAttempts) {
            logger.info(
              `Attempt ${attemptNum} of ${numAttempts}: Backing off for ${backoffMs}ms before retry`,
            );
          } else {
            logger.warn(
              `Exhausted ${numAttempts} retries, giving up with error ${e}`,
            );
          }
        } else {
          throw e; // stop retrying
        }
      },
    });
  }

  function fetchTypeaheadRecommendations(): Promise<Recommendation[]> {
    return getTypeaheadRecommendations(callApiV2);
  }

  function fetchSearchResults(
    searchQuery: string,
    filters: SearchFilter[] = [],
  ) {
    return getSearchResults(callApiV2WithResponseHeaders, searchQuery, filters);
  }

  function fetchImplicitAnswer(searchQuery: string): Promise<AnswerResponse> {
    return getImplicitAnswer(callApiV2, searchQuery);
  }

  function fetchAnswersForQuery(
    searchQuery: string,
  ): Promise<MultiAnswerResponse> {
    return getAnswersForQuery(callApiV2, searchQuery);
  }

  function fetchConversation(
    query: string,
    attachments?: dash.Attachment[],
    conversationId?: string,
  ): Promise<ConversationResponse> {
    return getConversation(callApiV2, query, attachments, conversationId);
  }

  function fetchUpstreamForConnection(
    searchQuery: string,
    connection: Connection,
    filters: SearchFilter[] = [],
  ) {
    return upstreamForConnection(
      callApiV2WithResponseHeaders,
      searchQuery,
      connection,
      filters,
    );
  }

  return services.provide(
    ServiceId.DBX_API,
    {
      callApiV2,
      fetchTypeaheadRecommendations,
      fetchSearchResults,
      fetchUpstreamForConnection,
      fetchImplicitAnswer,
      fetchAnswersForQuery,
      fetchConversation,
      grpc: {
        getLinkSummary,
        getAnswerForQuestion,
        getDocSummary,
        getDocAnswer,
      },
    },
    [ServiceId.DASH_AUTH, ServiceId.LOGOUT, ServiceId.OPERATIONAL_METRICS],
  );
}
