import { dash_feed, users } from '@dropbox/api-v2-client';
import { Metadata } from '@dropbox/dash-component-library';
import {
  ContentRow,
  ContentRowSkeleton,
} from '@mirage/dash-component-library/components/ContentRow';
import { SummarizeAction } from '@mirage/mosaics/SummaryQnaPanel/SummarizeAction';
import { ChatEntryPoint } from '@mirage/mosaics/SummaryQnaPanel/SummaryQna';
import { SummaryQnAEnum } from '@mirage/mosaics/SummaryQnaPanel/utils';
import { isStackActivityObject } from '@mirage/service-feed/util/helpers';
import { getConnectorDisplayNameFromResult } from '@mirage/shared/connectors';
import { toActivityResult } from '@mirage/shared/feed/activity-result';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n from '@mirage/translations';
import { ActivityAction } from './ActivityAction/ActivityAction';
import { ActivityThumbnail } from './ActivityThumbnail';

type ActivityListItemProps = {
  activity: dash_feed.ActivityItem;
  currentAccount?: users.FullAccount;
  index?: number;
  enableSummary?: boolean;
};

export const CompactActivityListItem = ({
  activity,
  currentAccount,
  index,
  enableSummary = false,
}: ActivityListItemProps) => {
  const isMobileSize = useIsMobileSize();
  return (
    <ContentRow
      showBadge={!activity.is_read}
      withStartAccessory={<ActivityThumbnail activity={activity} />}
      withLabel={
        <ActivityAction currentAccount={currentAccount} activity={activity} />
      }
      withMeta={<ActivityMetadata activity={activity} />}
      withActions={
        enableSummary && (
          <SummarizeAction
            result={activity.object}
            summaryQnAType={SummaryQnAEnum.ACTIVITY}
            entrypoint={ChatEntryPoint.activity_feed_summary}
            resultPosition={index!}
            // the SERP CTA is not shown in the feed so we don't need to worry about this here
            resultPositionNoCta={index!}
            summarizable={activity.object?.summarizable?.['.tag'] || undefined}
          />
        )
      }
      isMobile={isMobileSize}
    />
  );
};

const ActivityLabel = ({ activity }: ActivityListItemProps) => {
  let label: string;
  if (isStackActivityObject(activity)) {
    label = i18n.t('activity_feed_item_type_stack');
  } else {
    const result = toActivityResult(activity);
    if (!result || !result.connectorInfo) {
      return <></>;
    }
    label = getConnectorDisplayNameFromResult(result);
  }
  return <>{label}</>;
};

const ActivityMetadata = ({ activity }: ActivityListItemProps) => {
  return (
    <Metadata size="medium">
      <Metadata.Item>
        <Metadata.Label>
          <ActivityLabel activity={activity} />
        </Metadata.Label>
      </Metadata.Item>
      <Metadata.Item>{getTimeAgoString(activity.ts!)}</Metadata.Item>
    </Metadata>
  );
};

const CompactActivityListItemSkeleton = () => {
  return <ContentRowSkeleton />;
};

CompactActivityListItem.Skeleton = CompactActivityListItemSkeleton;
