import { SearchResultSource } from '@mirage/analytics/events/enums/search_result_source';

import type { dash } from '@dropbox/api-v2-client';
import type { SerpFinalRanking } from '@mirage/analytics/events/enums/serp_final_ranking';

export const DASH_DESKTOP_LOCAL_FILE_TAG = {
  '.tag': 'dash_desktop_local_file',
} as const;

export const DASH_DESKTOP_APPLICATION_TAG = {
  '.tag': 'dash_desktop_application',
} as const;

// This export is used by Unified Desktop (rPOLLUX) and should not be removed
export type SurfaceSpecificRecordType =
  | typeof DASH_DESKTOP_LOCAL_FILE_TAG
  | typeof DASH_DESKTOP_APPLICATION_TAG;

export function isDesktopLocalFile(result: SearchResult): boolean {
  return result.recordType?.['.tag'] === DASH_DESKTOP_LOCAL_FILE_TAG?.['.tag'];
}

export function isRecordTypeTagEvent(result: SearchResult): boolean {
  return result?.recordType?.['.tag'] === 'event';
}

export function isDesktopApplication(result: SearchResult): boolean {
  return result.recordType?.['.tag'] === DASH_DESKTOP_APPLICATION_TAG?.['.tag'];
}

export type DesktopLocalFileMetadata = {
  type: string;
  itemTypes: string[];
  pathSegments: string[];
};

export type DesktopApplicationMetadata = {
  type: ApplicationType;
  version: string;
};

export enum ApplicationType {
  SETTING = 'SETTING',
  APPLICATION = 'APPLICATION',
}

/**
 * A simple wrapper type that contains the dash.SearchResult
 * fields and null checks them so they are easier to handle throughout the app.
 * Also allows us to control the type definitions and add to them as needed.
 *
 */
export type SearchResult = {
  uuid: string;
  id3p: string | null;

  // connectorInfo
  connectorInfo: ConnectorInfo;
  displayIconOverride: dash.DisplayIconOverride | null;

  // provider record info
  upstreamId: string | null;
  // From Search Infra: this is a backend concept and they only support document, event, contact and unknown_record_type
  recordType: dash.RecordType | SurfaceSpecificRecordType;
  title: string;
  url: string | null;
  description: string | null;
  additionalLinks: Array<string>;
  conferenceLinks: dash.ConferenceLink[];

  // provider account info?
  email: string | null;
  profileImageUrl: string | null;

  // calender fields (record_type === 'event')
  startTime: number | null;
  endTime: number | null;
  isAllDay: boolean | null;
  location: string | null;
  recurringEventId: string | null;

  // Timestamp when the document was updated on the provider. Maps to the legacy upstream updated at
  providerUpdateAtMs: number | null;

  // updated timestamp for the server record. Not when the document itself was updated.
  updatedAtMs: number | null;

  summarizable: dash.SummarizableType['.tag'] | null;

  highlights: { [key: string]: dash.HighlightList } | null;

  // request id for the given query. used for analytics and ML training
  searchRequestId?: string;
  // trace id used to join events for ML And analytics, not specific to search. Use this
  // for all events going forward
  analyticsTraceId?: string;

  score?: number;
  serpFinalRanking?: SerpFinalRanking;
  latency?: number;

  searchResultSource?: SearchResultSource;

  // surface-specific result properties
  desktopLocalFileMetadata?: DesktopLocalFileMetadata;
  desktopApplicationMetadata?: DesktopApplicationMetadata;

  relevanceScore: number;

  fileTypeInfo?: FileTypeInfo | null;

  brandedSiteInfo?: BrandedSiteInfo | null;

  dataSource?: DataSource;

  parent?: ItemLocation | null;
  sender?: AuthorInfo | null;
  creator?: AuthorInfo | null;
  lastModifier?: AuthorInfo | null;
  sourceIndexType?: dash.SourceIndexType;
};

export type DataSource = {
  connectionId: string;
};

export type ConnectorInfo = {
  connectorId: string;
  connectorType: dash.ConnectorType;
  connectorName: string;
  displayName: string;
  connectorIconUrl: string; // Being deprecated in favor of IconResource
  platform: dash.ConnectorPlatform | null;
  icon?: IconResource | null;
};

export type FileTypeInfo = {
  id?: string;
  displayName?: string;
  icon: IconResource | null;
};

export type BrandedSiteInfo = {
  name?: string;
  icon: IconResource | null;
};

export type IconResource = {
  lightUrl?: string;
  darkUrl?: string;
};

export type ItemLocation = {
  displayName?: string;
  url?: string;
};

export type AuthorInfo = {
  displayName?: string;
  email?: string;
  profilePhotoUrl?: string;
};
