import { stacks } from '@dropbox/api-v2-client';
import { Metadata } from '@dropbox/dash-component-library';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { PAP_View_DashStack } from '@mirage/analytics/events/types/view_dash_stack';
import { ContentRow } from '@mirage/dash-component-library/components/ContentRow';
import { DashStackIcon } from '@mirage/dash-component-library/components/DashStackIcon';
import { openURL } from '@mirage/service-platform-actions';
import { stackDerivePAPProps } from '@mirage/service-stacks';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n from '@mirage/translations';
import { useCallback } from 'react';
import { ActivityActionBar } from '../ActivityActionBar';
import { type ActivityObjectProps } from '../ActivityObject';

type StackActivityObjectProps = {
  stack?: stacks.Stack;
  stackData: stacks.StackData;
} & ActivityObjectProps;

export const StackActivityObject = ({
  activity,
  stack,
  stackData,
  enableSummary,
  index,
}: StackActivityObjectProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const onClickRow = useCallback(() => {
    reportPapEvent(
      PAP_Open_DashLink({
        actionSurfaceComponent: 'activity_feed',
        featureLine: 'activity_feed',
      }),
    );
    openURL(activity.object?.url);

    if (stack) {
      reportPapEvent(
        PAP_View_DashStack({
          ...stackDerivePAPProps(stack),
          actionSurfaceComponent: 'activity_feed',
          featureLine: 'stacks',
        }),
      );
    }
  }, [activity.object?.url, stack, reportPapEvent]);

  return (
    <ContentRow
      paddingSize="medium"
      onClick={onClickRow}
      withStartAccessory={
        <StackActivityObjectIcon
          activity={activity}
          stack={stack}
          stackData={stackData}
        />
      }
      withLabel={stackData.name}
      withMeta={
        <StackActivityObjectMetadata
          activity={activity}
          stack={stack}
          stackData={stackData}
        />
      }
      withActions={
        <ActivityActionBar
          activity={activity}
          index={index}
          enableSummary={enableSummary}
        />
      }
    />
  );
};

const StackActivityObjectIcon = ({ stackData }: StackActivityObjectProps) => {
  return (
    <DashStackIcon
      size="large"
      colorIndex={stackData.color_index}
      emoji={stackData.emoji}
      shapeVariant="square"
    />
  );
};

const StackActivityObjectMetadata = ({ stack }: StackActivityObjectProps) => {
  if (!stack) {
    // TODO: remove this check when full_stack rolls out everywhere
    return null;
  }

  if (!stack.last_modified_time_utc_sec) {
    return null;
  }

  return (
    <Metadata size="medium">
      <Metadata.Item>
        <Metadata.Label>
          {i18n.t('updated_ago', {
            timeAgo: getTimeAgoString(stack.last_modified_time_utc_sec! * 1000),
          })}
        </Metadata.Label>
      </Metadata.Item>
    </Metadata>
  );
};
