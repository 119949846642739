import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { tagged } from '@mirage/service-logging';
import { ComposeSession } from '@mirage/shared/compose/compose-session';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import { KVStorage } from '@mirage/storage';

import type { LogoutServiceConsumerContract } from '@mirage/service-logout';

export type Service = ReturnType<typeof composeService>;

export interface ComposeSessionsStorage {
  sessions: {
    rows: ComposeSession[];
    version: 1;
  };
}

export interface ComposeVoicesStorage {
  voices: ComposeVoice[];
}

export enum StorageKey {
  Voices = 'voices',
  Sessions = 'sessions',
}

export default function composeService(
  sessionsStore: KVStorage<ComposeSessionsStorage>,
  voicesStore: KVStorage<ComposeVoicesStorage>,
  logoutService: LogoutServiceConsumerContract,
) {
  const logger = tagged('compose-service');

  async function loadComposeSessions(): Promise<ComposeSession[]> {
    const sessions = await sessionsStore.get(StorageKey.Sessions);
    if (sessions?.version === 1) {
      logger.log('loaded ComposeSessions', sessions.rows.length);
      return sessions.rows;
    } else {
      // Ignore old data if the version is not >=1
      return [];
    }
  }

  async function saveComposeSessions(sessions: ComposeSession[]) {
    await sessionsStore.set(StorageKey.Sessions, {
      rows: sessions,
      version: 1,
    });
    logger.log('saved ComposeSessions', sessions.length);
  }

  async function loadComposeVoices(): Promise<ComposeVoice[]> {
    const voices = (await voicesStore.get(StorageKey.Voices)) || [];
    logger.log('loaded ComposeVoices', voices?.length);
    return voices || [];
  }

  async function saveComposeVoices(voices: ComposeVoice[]) {
    await voicesStore.set(StorageKey.Voices, voices);
    logger.log('saved ComposeVoices', voices.length);
  }

  async function tearDown() {
    await sessionsStore.clear();
    await voicesStore.clear();
  }

  logoutService.registerLogoutCallback(ServiceId.COMPOSE, async () => {
    logger.debug('Handling logout in compose service');
    await tearDown();
    logger.debug('Done handling logout in compose service');
  });

  return services.provide(
    ServiceId.COMPOSE,
    {
      loadComposeSessions,
      saveComposeSessions,
      loadComposeVoices,
      saveComposeVoices,
      tearDown,
    },
    [ServiceId.DBX_API],
  );
}
