// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3467
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { BrowserName } from '../enums/browser_name';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'send.compose_prompt'
// Description: Submit a compose prompt
// Owner: dash-web
export type Send_ComposePrompt = PAPEventGeneric<
  'dash_assist',
  'send',
  'compose_prompt',
  {
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // This indicates the user's current browser
    browserName?: BrowserName;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Boolean event property that is set to true for Dash for Business (dfb) users.
    isDfbUser?: boolean;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // The contents of a search query
    queryString?: string;
  }
>;

export function PAP_Send_ComposePrompt(
  properties?: Send_ComposePrompt['properties'],
): Send_ComposePrompt {
  return <Send_ComposePrompt>{
    class: 'dash_assist',
    action: 'send',
    object: 'compose_prompt',
    properties,
  };
}
