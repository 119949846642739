import { Badge } from '@dropbox/dig-components/badge';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AiChatLine,
  EditLine,
  HelpLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import styles from './EmptyChatInstructions.module.css';

export type ClickableEmptyChatInstructionsOption =
  | 'write'
  | 'request_capabilities';
type EmptyChatInstructionsOption =
  | 'write'
  | 'request_capabilities'
  | 'chat_with_dash';

interface EmptyChatInstructionsProps {
  onClickOption: (option: ClickableEmptyChatInstructionsOption) => void;
}
export const EmptyChatInstructions = memo(
  ({ onClickOption }: EmptyChatInstructionsProps) => {
    return (
      <div className={styles.EmptyChatInstructions}>
        <Title size="small" weightVariant="emphasized" className={styles.Title}>
          Choose an option to get started
        </Title>
        <div className={styles.EmptyChatInstructionsCards}>
          {CARD_OPTIONS.map((option) => (
            <EmptyChatInstructionCard
              key={option}
              option={option}
              onClickOption={onClickOption}
            />
          ))}
        </div>
      </div>
    );
  },
);
EmptyChatInstructions.displayName = 'EmptyChatInstructions';

interface EmptyChatInstructionCardProps {
  option: EmptyChatInstructionsOption;
  onClickOption: (option: ClickableEmptyChatInstructionsOption) => void;
}
export const EmptyChatInstructionCard = memo(
  ({ option, onClickOption }: EmptyChatInstructionCardProps) => {
    const { title, description, icon, color, borderVariant } =
      getCardRenderInfo(option);
    const handleClick = useCallback(() => {
      if (option === 'chat_with_dash') {
        return;
      }
      onClickOption(option);
    }, [onClickOption, option]);
    return (
      <div
        className={classNames(styles.EmptyChatInstructionCard, {
          [styles.BorderVariantNormal]: borderVariant === 'normal',
          [styles.BorderVariantDotted]: borderVariant === 'dotted',
          [styles.BorderVariantComingSoon]: borderVariant === 'coming_soon',
          [styles.Clickable]: borderVariant !== 'coming_soon',
        })}
        onClick={handleClick}
        onKeyDown={onKeyDownCommitFn(handleClick)}
        tabIndex={0}
        role="button"
      >
        {borderVariant === 'coming_soon' && (
          <Badge size="xsmall" over="base" className={styles.ComingSoonBadge}>
            {i18n.t('coming_soon_label')}
          </Badge>
        )}
        <UIIcon src={icon} size="large" color={color} />
        <Title
          size="small"
          weightVariant="emphasized"
          className={styles.CardTitle}
          tagName="div"
        >
          {title}
        </Title>
        <Text size="medium" color="subtle" className={styles.CardDescription}>
          {description}
        </Text>
      </div>
    );
  },
);
EmptyChatInstructionCard.displayName = 'EmptyChatInstructionCard';

const CARD_OPTIONS: EmptyChatInstructionsOption[] = [
  'write',
  'request_capabilities',
  'chat_with_dash',
];

function getCardRenderInfo(option: EmptyChatInstructionsOption) {
  switch (option) {
    case 'chat_with_dash':
      return {
        title: i18n.t('assistant_card_title_chat_with_dash'),
        description: i18n.t('assistant_card_description_chat_with_dash'),
        icon: AiChatLine,
        color: 'var(--dig-color__accent__cyan)',
        borderVariant: 'coming_soon',
      };
    case 'request_capabilities':
      return {
        title: i18n.t('assistant_card_title_request_capabilities'),
        description: i18n.t('assistant_card_description_request_capabilities'),
        icon: HelpLine,
        color: 'var(--dig-color__accent__purple)',
        borderVariant: 'dotted',
      };
    case 'write':
      return {
        title: i18n.t('assistant_card_title_write'),
        description: i18n.t('assistant_card_description_write'),
        icon: EditLine,
        color: 'var(--dig-color__accent__red)',
        borderVariant: 'normal',
      };
    default:
      option satisfies never;
      throw new Error(`Unknown option: ${option}`);
  }
}
