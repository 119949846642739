import { UIIcon } from '@dropbox/dig-icons';
import { AddCircleLine } from '@dropbox/dig-icons/assets';
import { PAP_Click_NewSessionButton } from '@mirage/analytics/events/types/click_new_session_button';
import {
  ComposeAssistantGrid,
  ComposeAssistantPageWrapper,
} from '@mirage/mosaics/ComposeAssistant/components/ComposeAssistantPageLayout';
import { ToggleExpandButton } from '@mirage/mosaics/ComposeAssistant/components/layouts/ToggleExpandButton';
import { SideBarContainer } from '@mirage/mosaics/ComposeAssistant/components/side-bar/SideBarContainer';
import { SidePanelContainer } from '@mirage/mosaics/ComposeAssistant/components/side-panel/SidePanelContainer';
import { ComposeConversation } from '@mirage/mosaics/ComposeAssistant/containers/ComposeConversation';
import { ComposeEditorPane } from '@mirage/mosaics/ComposeAssistant/containers/ComposeEditorPane';
import { ComposeSessionsPane } from '@mirage/mosaics/ComposeAssistant/containers/ComposeSessionsPane';
import {
  ComposeAnalyticsContextProvider,
  useComposeAnalyticsContext,
} from '@mirage/mosaics/ComposeAssistant/data/ComposeAnalyticsContext';
import { ComposeSessionsContextProvider } from '@mirage/mosaics/ComposeAssistant/data/ComposeSessionsContext';
import { ComposeVoicesContextProvider } from '@mirage/mosaics/ComposeAssistant/data/ComposeVoicesContext';
import {
  ComposeCurrentSessionContextProvider,
  useComposeCurrentSessionContext,
} from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { ComposeArtifact } from '@mirage/shared/compose/compose-session';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import { memo, useEffect, useState } from 'react';

export const ComposeAssistantPage = memo(() => {
  return (
    <ComposeAnalyticsContextProvider>
      <ComposeSessionsContextProvider>
        <ComposeVoicesContextProvider>
          <ComposeCurrentSessionContextProvider>
            <ComposeAssistantPageWrapper>
              <ComposeAssistantPageContent />
            </ComposeAssistantPageWrapper>
          </ComposeCurrentSessionContextProvider>
        </ComposeVoicesContextProvider>
      </ComposeSessionsContextProvider>
    </ComposeAnalyticsContextProvider>
  );
});
ComposeAssistantPage.displayName = 'ComposeAssistantPage';

const ComposeAssistantPageContent = memo(() => {
  const { logComposeEvent } = useComposeAnalyticsContext('compose_side_bar');
  const { artifacts } = useComposeCurrentSessionContext();
  const [keepSideBarExpanded, setKeepSideBarExpanded] = useState(true);
  const [rightPaneExpanded, setRightPaneExpanded] = useState(() =>
    hasNonEmptyArtifacts(artifacts),
  );
  useEffect(() => {
    setRightPaneExpanded(hasNonEmptyArtifacts(artifacts));
  }, [artifacts]);
  const sidePanelButtons = !keepSideBarExpanded && (
    <>
      <ToggleExpandButton
        setExpanded={setKeepSideBarExpanded}
        variant="expand"
        logComposeEvent={logComposeEvent}
      />
      <NewSessionButton />
    </>
  );
  return (
    <ComposeAssistantGrid
      sideBar={
        <ComposeAssistSideBar
          keepExpanded={keepSideBarExpanded}
          setKeepExpanded={setKeepSideBarExpanded}
        />
      }
      sidePane={
        <SidePanelContainer leftTitleActions={sidePanelButtons}>
          <ComposeConversation />
        </SidePanelContainer>
      }
      rightPane={rightPaneExpanded && <ComposeEditorPane />}
      setExpandRightPane={setRightPaneExpanded}
      disableExpandRightPane={artifacts.length === 0}
      logComposeEvent={logComposeEvent}
    />
  );
});
ComposeAssistantPageContent.displayName = 'ComposeAssistantPageContent';

interface ComposeAssistSideBarProps {
  keepExpanded: boolean;
  setKeepExpanded: (expanded: boolean) => void;
}
export const ComposeAssistSideBar = memo(
  ({ keepExpanded, setKeepExpanded }: ComposeAssistSideBarProps) => {
    const { logComposeEvent } = useComposeAnalyticsContext('compose_side_bar');
    const collapseSideBarButton = (
      <ToggleExpandButton
        logComposeEvent={logComposeEvent}
        setExpanded={setKeepExpanded}
        variant="collapse"
      />
    );
    return (
      <SideBarContainer
        headerActionLeft={keepExpanded ? collapseSideBarButton : undefined}
        headerActionRight={<NewSessionButton />}
        keepExpanded={keepExpanded}
      >
        <ComposeSessionsPane />
      </SideBarContainer>
    );
  },
);
ComposeAssistSideBar.displayName = 'ComposeAssistSideBar';

export const NewSessionButton = memo(() => {
  const { logComposeEvent } = useComposeAnalyticsContext('compose_side_bar');
  const { newSession } = useComposeCurrentSessionContext();
  return (
    <IconButtonWithTooltip
      variant="borderless"
      tooltipProps={{
        title: i18n.t('compose_new_session'),
      }}
      onClick={() => {
        newSession([]);
        logComposeEvent(PAP_Click_NewSessionButton());
      }}
    >
      <UIIcon src={AddCircleLine} />
    </IconButtonWithTooltip>
  );
});
NewSessionButton.displayName = 'NewSessionButton';

function hasNonEmptyArtifacts(artifacts: ComposeArtifact[]) {
  return artifacts.some((artifact) => artifact.markdownContent.length > 0);
}
