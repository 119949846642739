import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { ToggleExpandButton } from '@mirage/mosaics/ComposeAssistant/components/layouts/ToggleExpandButton';
import classNames from 'classnames';
import { Resizable } from 're-resizable';
import { memo } from 'react';
import styles from './ComposeAssistantPageLayout.module.css';

interface ComposeAssistantPageWrapperProps {
  children: React.ReactNode;
}
export const ComposeAssistantPageWrapper = memo(
  ({ children }: ComposeAssistantPageWrapperProps) => {
    return <div className={styles.wrapper}>{children}</div>;
  },
);
ComposeAssistantPageWrapper.displayName = 'ComposeAssistantPageWrapper';

interface ComposeAssistantGridProps {
  sideBar: React.ReactNode;
  sidePane: React.ReactNode;
  rightPane: React.ReactNode;
  setExpandRightPane: (expanded: boolean) => void;
  disableExpandRightPane: boolean;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
}
export const ComposeAssistantGrid = memo(
  ({
    sideBar,
    sidePane,
    rightPane,
    setExpandRightPane,
    disableExpandRightPane,
    logComposeEvent,
  }: ComposeAssistantGridProps) => {
    return (
      <div className={styles.ComposeAssistantGrid}>
        <div className={styles.ComposeAssistantGridSideBar}>{sideBar}</div>
        {rightPane ? (
          <Resizable
            defaultSize={{ width: '35%' }}
            minWidth={280}
            enable={{ right: true }}
            className={styles.ComposeAssistantGridSidePane}
          >
            {sidePane}
          </Resizable>
        ) : (
          <div
            className={classNames(
              styles.ComposeAssistantGridSidePane,
              styles.ComposeAssistantGridSidePaneNoResize,
            )}
            style={{ width: '100%' }}
          >
            {sidePane}
          </div>
        )}
        <div
          className={classNames(styles.ComposeAssistantGridRightPane, {
            [styles.ComposeAssistantGridRightPaneExpanded]: !!rightPane,
          })}
        >
          {rightPane || (
            <div className={styles.RightPaneToggleContainer}>
              <ToggleExpandButton
                setExpanded={setExpandRightPane}
                variant={rightPane ? 'collapse' : 'expand'}
                disabled={disableExpandRightPane}
                logComposeEvent={logComposeEvent}
              />
            </div>
          )}
        </div>
      </div>
    );
  },
);
ComposeAssistantGrid.displayName = 'ComposeAssistantGrid';
