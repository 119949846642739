import { tagged } from '@mirage/service-logging';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { titleTokenizer } from '@mirage/shared/search/tokenizers';
import escapeRegExp from 'lodash.escaperegexp';

const logger = tagged('service-typeahead-search/scoring/title-match');

type TokenizerFn = (sequence: string) => string[];

export function titleMatchScore(query: string, result: typeahead.TaggedResult) {
  const title = extractTitle(result);

  if (!title) return 0;

  return similarity(query, title);
}

// WARN: Cross-reference `export enum ResultType`
function extractTitle(result: typeahead.TaggedResult): string | undefined {
  switch (result.type) {
    case typeahead.ResultType.PreviousQuery:
      return result.result.query;
    case typeahead.ResultType.SearchResult:
      return result.result.title;
    case typeahead.ResultType.Recommendation:
      return result.result.title;
    case typeahead.ResultType.Stack: {
      const stackName = result.result.stack_data?.name;
      if (stackName) {
        return stackName;
      } else {
        logger.warn(`Couldn't get title to score, Stack has no name`);
        return undefined;
      }
    }
    case typeahead.ResultType.StackItem:
      return result.result.name;
    case typeahead.ResultType.DesktopApplication:
      return result.result.title;
    case typeahead.ResultType.DesktopFile:
      return result.result.title;
    default: {
      result.type satisfies
        | typeahead.ResultType.URLShortcut
        | typeahead.ResultType.MathCalculation
        | typeahead.ResultType.SuggestedQuery
        | typeahead.ResultType.SearchFilter;
      logger.warn(
        `Couldn't get title to score, no title for result.type ${result.type}, check title-match`,
      );
      return undefined;
    }
  }
}

function similarity(
  query: string,
  title: string,
  tokenize: TokenizerFn = titleTokenizer,
): number {
  // skip evaluation if empty query or title is present
  if (!query) return 0;
  if (!title) return 0;

  // ignore case
  query = query.toLowerCase();
  title = title.toLowerCase();

  // exact match
  if (query === title) return 1;

  // tokenize query and title
  // TODO: evaluate removing duplicate tokens
  const qtokens = tokenize(query);
  const ttokens = tokenize(title);

  // query vs. title match heuristics
  const queryTokenExactMatchesScore_ = queryTokenExactMatchesScore(
    qtokens,
    ttokens,
  );
  const queryTokenPrefixMatchesScore_ = queryTokenPrefixMatchesScore(
    qtokens,
    ttokens,
  );
  const titleCoverageScore_ = titleCoverageScore(qtokens, title);
  const firstQueryTokenOccurenceScore_ = firstQueryTokenOccurenceScore(
    qtokens,
    title,
  );

  // weighted score
  const score =
    queryTokenExactMatchesScore_ * 0.5 +
    queryTokenPrefixMatchesScore_ * 0.2 +
    titleCoverageScore_ * 0.2 +
    firstQueryTokenOccurenceScore_ * 0.1;

  return score;
}

//
// Q: what % of query tokens are contained in the title as full word matches?
// ----------------------------------------------------------------------------
export function queryTokenExactMatchesScore(
  qtokens: string[],
  ttokens: string[],
): number {
  const qmatches = qtokens.filter((qtoken) => ttokens.includes(qtoken));

  // Performance - avoid math when unnecessary
  if (qmatches.length === 0) return 0.0;

  return qmatches.length / qtokens.length;
}

//
// Q: what % of query tokens are contained in the title as prefix matches?
// ----------------------------------------------------------------------------
export function queryTokenPrefixMatchesScore(
  qtokens: string[],
  ttokens: string[],
): number {
  const qmatches = qtokens.filter((qtoken) =>
    ttokens.some((ttoken) => ttoken.startsWith(qtoken)),
  );

  // Performance - avoid math when unnecessary
  if (qmatches.length === 0) return 0.0;

  return qmatches.length / qtokens.length;
}

//
// Q: how much of the title is covered by query tokens?
// ----------------------------------------------------------------------------
export function titleCoverageScore(qtokens: string[], title: string) {
  const remainingTitle = qtokens.reduce((remaining, qtoken) => {
    const re = new RegExp(escapeRegExp(qtoken), 'g');
    return remaining.replace(re, '');
  }, title);

  // Performance - avoid math when unnecessary
  if (remainingTitle.length === 0) return 1.0;

  return 1 - remainingTitle.length / title.length;
}

//
// Q: how soon into the title is a query token substring found?
// ----------------------------------------------------------------------------
export function firstQueryTokenOccurenceScore(
  qtokens: string[],
  title: string,
) {
  const matchingLocations = qtokens
    .map((qtoken) => title.indexOf(qtoken))
    .filter((index) => index >= 0);

  // Performance - avoid math when unnecessary
  if (!matchingLocations.length) return 0;

  const earliestMatch = Math.min(...matchingLocations);

  return 1 - earliestMatch / title.length;
}
